import React from 'react';
import { useSelector } from 'react-redux';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

import { Store } from '../../../state/store.interfaces';
import { formatLongDateWithZone } from '../../../utils/date';
import { roundTwoDecimals, weightUnits, lengthUnits, animalDaysUnit } from '../../../config/commons';
import { applyThousandsSeparator } from '../../../utils/strings';
import { calcDensityLarvae } from '../../../helpers/stocking.helpers';
import { calculateBinCenters, calculateWeightedAverage, calculateWeightedStdDev, calculateCoefficientOfVariation } from '../../Harvests/helpers';

import styles from './Data.module.scss';

export default function DataDetail () {
  const [t] = useTranslation();
  const { histograms } = useSelector((state: Store) => state.harvests);
  const { consolidated, harvest } = histograms;

  const renderRow = (props: { label: string; value: string | number; }) => {
    const { label, value } = props;

    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {value}
        </Text>
        <Text className={styles.field}>
          {label}
        </Text>
      </div>
    );
  };

  const renderHarvestInformation = () => {
    if (!harvest?.stocking?._id) {
      return null;
    }

    return (
      <div className={styles.row}>
        {renderRow({ label: t('harvests.detail.harvested'), value: applyThousandsSeparator(harvest.stocking.harvestQuantity) })}
        {renderRow({ label: t('harvests.detail.liters'), value: applyThousandsSeparator(harvest.stocking.litersNumber) })}
        {renderRow({ label: t('harvests.detail.density'), value: `${calcDensityLarvae({ litersNumber: harvest.stocking.litersNumber, naupliusNumber: harvest.stocking.naupliusNumber })} ${t('stockings.densityNauplii')}` })}
        {renderRow({ label: t('harvests.detail.animals'), value: applyThousandsSeparator(harvest.stocking.naupliusNumber) })}
        {renderRow({ label: t('harvests.detail.larvaePerGram'), value: harvest.stocking.larvaePerGram })}
        {renderRow({ label: t('harvests.detail.stage'), value: `${harvest.stocking.stage} ${animalDaysUnit.PL}` })}
        {renderRow({ label: t('harvests.detail.survival'), value: `${roundTwoDecimals(harvest.stocking.survivalRate)}%` })}
        {renderRow({ label: t('harvests.harvestDate'), value: formatLongDateWithZone({ date: harvest.stocking.endDate }) })}
      </div>
    );
  };

  const renderConsolidatedInformation = () => {
    if (!consolidated?._id) {
      return null;
    }

    const harvestQuantity = consolidated.harvestedAnimals.reduce((acc, value) => acc + value, 0);

    const binCentersWeight = calculateBinCenters(consolidated.histogramWeight.limits);
    const averageWeight = calculateWeightedAverage(binCentersWeight, consolidated.histogramWeight.frequencies);
    const stdDevWeight = calculateWeightedStdDev(binCentersWeight, consolidated.histogramWeight.frequencies, averageWeight);
    const variationCoefficientWeight = calculateCoefficientOfVariation(stdDevWeight, averageWeight) * 100;

    const binCentersLength = calculateBinCenters(consolidated.histogramLength.limits);
    const averageLength = calculateWeightedAverage(binCentersLength, consolidated.histogramLength.frequencies);
    const stdDevLength = calculateWeightedStdDev(binCentersLength, consolidated.histogramLength.frequencies, averageLength);
    const variationCoefficientLength = calculateCoefficientOfVariation(stdDevLength, averageLength) * 100;

    return (
      <>
        <div className={styles.row}>
          {renderRow({ label: t('harvests.detail.harvested'), value: applyThousandsSeparator(harvestQuantity) })}
          {renderRow({ label: t('harvests.detail.averageWeight'), value: roundTwoDecimals(averageWeight) + ' ' + weightUnits.MG })}
          {renderRow({ label: t('harvests.detail.averageLength'), value: roundTwoDecimals(averageLength) + ' ' + lengthUnits.MM })}
          {renderRow({ label: t('harvests.detail.variationCoefficientWeight'), value: `${roundTwoDecimals(variationCoefficientWeight)} %` })}
          {renderRow({ label: t('harvests.detail.variationCoefficientLength'), value: `${roundTwoDecimals(variationCoefficientLength)} %` })}
          {renderRow({ label: t('harvests.harvestDate'), value: formatLongDateWithZone({ date: consolidated.endDate }) })}
        </div>

        {consolidated.stockingIds.map((stocking, index) => {
          return (
            <div className={styles.harvest}>
              <div className={styles.harvestName}>{stocking.name}</div>

              <div className={styles.row}>
                {renderRow({ label: t('harvests.animalsHarvested'), value: applyThousandsSeparator(consolidated.harvestedAnimals[index]) })}
                {renderRow({ label: '% ' + t('harvests.animalsHarvested'), value: roundTwoDecimals(consolidated.harvestedAnimals[index] * 100 / harvestQuantity) + ' %' })}
                {renderRow({ label: t('harvests.detail.stage'), value: `${stocking.stage} ${animalDaysUnit.PL}` })}
                {renderRow({ label: t('harvests.detail.larvaePerGram'), value: stocking.larvaePerGram })}
                {renderRow({ label: t('harvests.unit'), value: consolidated.campusIds[index].name })}
                {renderRow({ label: t('harvests.module'), value: consolidated.moduleIds[index].name })}
                {renderRow({ label: t('harvests.container'), value: consolidated.tankIds[index].name })}
                {renderRow({ label: t('harvests.detail.stockingBindingCode'), value: stocking.stockingBindingCode })}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className={styles.gridContainer}>
      {renderHarvestInformation()}
      {renderConsolidatedInformation()}
    </div>
  );
}
