import React, { useEffect } from 'react';
import { Row, Select, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { changeHeader } from '../../AppHeader/headerSlice';
import { filterOptionSelect } from '../../../utils/select';
import { getSortType } from '../../Sowings/sowings.helpers';
import { formatLongDateWithZone } from '../../../utils/date';
import { LrvTag } from '../../../common/components/LrvTag/LrvTag';
import { DropdownProps } from '../../../common/interfaces/commons';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import CleanButton from '../../../common/components/buttons/CleanButton';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import TinyCounter from '../../../common/components/TinyCounter/TinyCounter';
import { FetchStockingParams, STOCKINGS_SORT } from '../../Sowings/interfaces';
import { SortTitleCell } from '../../../common/components/SortTitleCell/SortTitleCell';
import { LrvFilterPanel } from '../../../common/components/LrvSideFloatingPanel/LrvFilterPanel';
import { CONTAINER_LABEL, stockingPhaseTypes, THEME, unitStatuses } from '../../../config/commons';
import { getStartDateStocking, getUnitPhaseTypeFromStocking } from '../../../helpers/stocking.helpers';

import { Stocking } from './interfaces';
import styles from './ArchivedStocking.module.scss';
import * as archivedAnalysisSlice from './archivedStockingSlice';
import stylesDropdownOptions from './DropdownArchivedStockingOptions.module.scss';
import { DropdownArchivedStockingOptions } from './DropdownArchivedStockingOptions';

const { Option } = Select;

interface Props {
  theme?: 'dark' | 'light';
}

export default function ArchivedStocking (props: Props) {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    stockings,
    campuses,
    modules,
    tanks,
    currentPage,
    isLoading,
    limit,
    total,
    sorts,
    campusSelected,
    moduleIdSelected,
    tankIdSelected,
  } = useSelector((state: Store) => state.archivedStocking);
  const { company: companySelected, phaseType } = useSelector((state: Store) => state.header);

  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    dispatch(changeHeader({ title: 'archive.header' }));
  }, [dispatch]);

  useEffect(() => {
    if (!companySelected._id) {
      return;
    }

    const params: FetchStockingParams = {
      page: 1,
      companyId: companySelected._id,
      phaseType,
    };

    const unitPhaseType = getUnitPhaseTypeFromStocking(phaseType);
    dispatch(archivedAnalysisSlice.fetchArchivedStocking(params));
    dispatch(archivedAnalysisSlice.fetchCampuses({ phaseType: unitPhaseType, companyId: companySelected._id }));
  }, [dispatch, companySelected._id, phaseType]);

  const dataSource = stockings.map((stocking: Stocking) => {
    const startDate = getStartDateStocking({ stocking, phaseType });

    return {
      ...stocking,
      date: formatLongDateWithZone({ date: startDate }),
    };
  });

  const columnsType: ColumnsType<Stocking> = [
    {
      key: 1,
      width: '5%',
      render: (record: Stocking) => {
        return (
          <TinyCounter title={t('stockings.analysisCount')} text={record.analysisCount?.toString()} className={isLightTheme ? styles.analysisCountLight : styles.analysisCountDark} />
        );
      },
    },
    {
      key: 2,
      width: '22%',
      title: t('stockings.name'),
      dataIndex: 'name',
      ellipsis: { showTitle: false },
    },
    {
      key: 3,
      width: '15%',
      dataIndex: ['campusId', 'name'],
      ellipsis: { showTitle: false },
      className: isLightTheme ? styles.sortColumnCellLight : styles.sortColumnCellDark,
      responsive: ['lg'] as Breakpoint[],
      title: () => {
        return (
          <SortTitleCell sortType={sorts.byCampusName} title={t('stockings.campus')} theme={theme} />
        );
      },
      onHeaderCell: () => {
        return {
          onClick: () => {
            dispatch(archivedAnalysisSlice.changeSorts({
              sortBy: STOCKINGS_SORT.CAMPUS_NAME,
            }));

            const params: FetchStockingParams = {
              page: 1,
              companyId: companySelected._id,
              campusId: campusSelected?._id,
              moduleId: moduleIdSelected,
              tankId: tankIdSelected,
              phaseType,
              sortByCampusName: getSortType(sorts.byCampusName),
              sortByModuleName: sorts.byModuleName,
              sortByTankName: sorts.byTankName,
              sortByStockingDate: sorts.byStockingDate,
            };
            dispatch(archivedAnalysisSlice.fetchArchivedStocking(params));
          }
        };
      },
      render: (name: string) => <>{name}</>,
    },
    {
      key: 4,
      width: '15%',
      dataIndex: ['moduleId', 'name'],
      className: isLightTheme ? styles.sortColumnCellLight : styles.sortColumnCellDark,
      ellipsis: { showTitle: false },
      responsive: ['lg'] as Breakpoint[],
      title: () => {
        return (
          <SortTitleCell sortType={sorts.byModuleName} title={t('stockings.module')} theme={theme} />
        );
      },
      onHeaderCell: () => {
        return {
          onClick: () => {
            dispatch(archivedAnalysisSlice.changeSorts({
              sortBy: STOCKINGS_SORT.MODULE_NAME,
            }));

            const params: FetchStockingParams = {
              page: 1,
              companyId: companySelected._id,
              campusId: campusSelected?._id,
              moduleId: moduleIdSelected,
              tankId: tankIdSelected,
              phaseType,
              sortByCampusName: sorts.byCampusName,
              sortByModuleName: getSortType(sorts.byModuleName),
              sortByTankName: sorts.byTankName,
              sortByStockingDate: sorts.byStockingDate,
            };
            dispatch(archivedAnalysisSlice.fetchArchivedStocking(params));
          }
        };
      },
      render: (name: string) => <>{name}</>,
    },
    {
      key: 5,
      width: '15%',
      dataIndex: ['tankId', 'name'],
      className: isLightTheme ? styles.sortColumnCellLight : styles.sortColumnCellDark,
      ellipsis: { showTitle: false },
      responsive: ['lg'] as Breakpoint[],
      title: () => {
        return (
          <SortTitleCell sortType={sorts.byTankName} title={t('stockings.container')} theme={theme} />
        );
      },
      onHeaderCell: () => {
        return {
          onClick: () => {
            dispatch(archivedAnalysisSlice.changeSorts({
              sortBy: STOCKINGS_SORT.TANK_NAME,
            }));

            const params: FetchStockingParams = {
              page: 1,
              companyId: companySelected._id,
              campusId: campusSelected?._id,
              moduleId: moduleIdSelected,
              tankId: tankIdSelected,
              phaseType,
              sortByCampusName: sorts.byCampusName,
              sortByModuleName: sorts.byModuleName,
              sortByTankName: getSortType(sorts.byTankName),
              sortByStockingDate: sorts.byStockingDate,
            };
            dispatch(archivedAnalysisSlice.fetchArchivedStocking(params));
          }
        };
      },
      render: (name: string) => <>{name}</>,
    },
    {
      key: 6,
      width: '15%',
      dataIndex: 'date',
      className: isLightTheme ? styles.sortColumnCellLight : styles.sortColumnCellDark,
      ellipsis: { showTitle: false },
      responsive: ['md'] as Breakpoint[],
      title: () => {
        return (
          <SortTitleCell sortType={sorts.byStockingDate} title={t('stockings.startDate')} theme={theme} />
        );
      },
      onHeaderCell: () => {
        return {
          onClick: () => {
            dispatch(archivedAnalysisSlice.changeSorts({
              sortBy: STOCKINGS_SORT.STOCKING_DATE,
            }));

            const params: FetchStockingParams = {
              page: 1,
              companyId: companySelected._id,
              campusId: campusSelected?._id,
              moduleId: moduleIdSelected,
              tankId: tankIdSelected,
              phaseType,
              sortByCampusName: sorts.byCampusName,
              sortByModuleName: sorts.byModuleName,
              sortByTankName: sorts.byTankName,
              sortByStockingDate: getSortType(sorts.byStockingDate),
            };
            dispatch(archivedAnalysisSlice.fetchArchivedStocking(params));
          }
        };
      },
    },
    {
      key: 7,
      width: '10%',
      dataIndex: 'stage',
      ellipsis: { showTitle: false },
      title: t('stockings.stage'),
      responsive: ['sm'] as Breakpoint[],
    },
    {
      key: 8,
      width: '5%',
      render: (record: Stocking) => {
        return (
          <Space align='center' className={styles.statusCell}>
            <DropdownArchivedStockingOptions stocking={record} theme={theme} />
          </Space>
        );
      }
    },
  ];

  const onChangePage = (page: number) => {
    const params = {
      page,
      companyId: companySelected._id,
      campusId: campusSelected?._id,
      moduleId: moduleIdSelected,
      tankId: tankIdSelected,
      phaseType,
      sortByCampusName: sorts.byCampusName,
      sortByModuleName: sorts.byModuleName,
      sortByTankName: sorts.byTankName,
      sortByStockingDate: sorts.byStockingDate,
    };

    dispatch(archivedAnalysisSlice.fetchArchivedStocking(params));
    dispatch(archivedAnalysisSlice.setCurrentPage(page));
  };

  const onChangeCampus = (value: string) => {
    const campus = campuses.find((item) => item._id === value);

    if (!campus?._id) {
      return;
    }

    const params = {
      page: 1,
      companyId: companySelected._id,
      campusId: campus._id,
      phaseType,
      sortByCampusName: sorts.byCampusName,
      sortByModuleName: sorts.byModuleName,
      sortByTankName: sorts.byTankName,
      sortByStockingDate: sorts.byStockingDate,
    };

    dispatch(archivedAnalysisSlice.setCampusSelected(campus));
    dispatch(archivedAnalysisSlice.setModuleSelected(undefined));
    dispatch(archivedAnalysisSlice.setTankSelected(undefined));
    dispatch(archivedAnalysisSlice.fetchModules(campus._id));

    dispatch(archivedAnalysisSlice.fetchArchivedStocking(params));
  };

  const renderCampusesDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_campuses'
        showSearch
        theme={theme}
        className={className}
        value={campusSelected?.name || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        disabled={!phaseType}
        dropdownMatchSelectWidth={false}
        title={t('campus.campus')}
        placeholder={t('archive.selectUnit')}
        onChange={onChangeCampus}
        filterOption={filterOptionSelect}
      >
        {campuses.map((campus) => {
          return (
            <Option key={campus._id} value={campus._id} label={campus.name}>
              {campus.name}
              {
                campus.status === unitStatuses.INACTIVE
                &&
                <>
                  &nbsp; &nbsp;
                  <LrvTag color='#e04d00'>{t('campus.inactive')}</LrvTag>
                </>
              }
            </Option>
          );
        })}
      </LrvSelect>
    );
  };

  const renderModulesDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_modules'
        theme={theme}
        className={className}
        value={moduleIdSelected || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        showSearch
        title={t('stockings.selectModule')}
        placeholder={t('stockings.selectModule')}
        optionFilterProp='children'
        disabled={!campusSelected?._id}
        onChange={(value) => {
          dispatch(archivedAnalysisSlice.setModuleSelected(value));
          dispatch(archivedAnalysisSlice.setTankSelected(undefined));

          dispatch(archivedAnalysisSlice.fetchTanks({ campusId: campusSelected?._id, moduleId: value }));

          const params = {
            page: 1,
            companyId: companySelected._id,
            campusId: campusSelected?._id,
            moduleId: value,
            phaseType,
            sortByCampusName: sorts.byCampusName,
            sortByModuleName: sorts.byModuleName,
            sortByTankName: sorts.byTankName,
            sortByStockingDate: sorts.byStockingDate,
          };
          dispatch(archivedAnalysisSlice.fetchArchivedStocking(params));
        }}
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
      >
        {modules.map((module) => {
          if (module.phaseType === phaseType) {
            return <Option key={module._id} value={module._id}>{module.name}</Option>;
          }
        })}
      </LrvSelect>
    );
  };

  const renderTanksFilter = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_tanks'
        value={tankIdSelected || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        showSearch
        theme={theme}
        className={className}
        title={t(`stockings.selectTank.${CONTAINER_LABEL[phaseType ?? stockingPhaseTypes.LARVAE]}`)}
        placeholder={t(`stockings.selectTank.${CONTAINER_LABEL[phaseType ?? stockingPhaseTypes.LARVAE]}`)}
        optionFilterProp='children'
        onChange={(value) => {
          dispatch(archivedAnalysisSlice.setTankSelected(value));

          const params = {
            page: 1,
            companyId: companySelected._id,
            campusId: campusSelected?._id,
            moduleId: moduleIdSelected,
            tankId: value,
            phaseType,
            sortByCampusName: sorts.byCampusName,
            sortByModuleName: sorts.byModuleName,
            sortByTankName: sorts.byTankName,
            sortByStockingDate: sorts.byStockingDate,
          };
          dispatch(archivedAnalysisSlice.fetchArchivedStocking(params));
        }}
        filterOption={filterOptionSelect}
        disabled={!(campusSelected?._id && moduleIdSelected)}
        dropdownMatchSelectWidth={false}
      >
        {tanks.map((tank) => <Option key={tank._id} value={tank._id}>{tank.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderCleanButton = () => {
    return (
      <CleanButton
        theme={theme}
        onClick={() => dispatch(archivedAnalysisSlice.resetArchivedStockingFilters({ company: companySelected, phaseType }))}
      />
    );
  };

  const renderSidePanel = () => {
    return (
      <div className={styles.sidePanel}>
        <LrvFilterPanel
          showFilterIcon
          title={<div className={styles.title}>{t('stockings.title')}</div>}
          cleanButtonProps={{
            onClick: () => {
              dispatch(archivedAnalysisSlice.resetArchivedStockingFilters({ company: companySelected, phaseType }));
            },
          }}
        >
          <Space
            className={styles.bodyPanel}
            direction='vertical'
          >
            {renderCampusesDropdown({ theme: 'light' })}
            {renderModulesDropdown({ theme: 'light' })}
            {renderTanksFilter({ theme: 'light' })}
          </Space>
        </LrvFilterPanel>
      </div>
    );
  };

  const renderSubHeader = () => {
    return (
      <Row className={styles.rowHeader}>
        <Space className={styles.filters} align='end'>
          {renderCampusesDropdown({ className: styles.select, theme })}
          {renderModulesDropdown({ className: styles.select, theme })}
          {renderTanksFilter({ className: styles.select, theme })}
          {renderCleanButton()}
        </Space>
        {renderSidePanel()}
      </Row>
    );
  };

  return (
    <div className={styles.archivedStocking} >
      {renderSubHeader()}

      <LrvTable
        className={styles.table}
        columns={columnsType}
        rowClassName={stylesDropdownOptions.stockingRow}
        dataSource={dataSource}
        loading={isLoading}
        scroll={{ y: '' }}
        size='small'
        theme={theme}
        pagination={{
          size: 'default',
          defaultPageSize: limit,
          pageSize: limit,
          total: total,
          onChange: onChangePage,
          showSizeChanger: false,
          current: currentPage
        }}
        onRow={(record: Stocking) => {
          return {
            onClick: (e) => {
              e.stopPropagation();
              history.push(`/archive/stockings/${record._id}`);
            }
          };
        }}
      />
    </div>
  );
}
