import { useState } from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../../utils/select';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { LrvText } from '../../../common/components/LrvText/LrvText';

import * as companyAnalysisObservationSlice from './companyAnalysisObservationSlice';
import { CompanyAnalysisObservation, GlobalAnalysisObservation } from './interfaces';

interface Props {
  theme?: 'dark' | 'light';
}

const { Option } = Select;

export const NewObservationForm = (props: Props) => {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const { company } = useSelector((state: Store) => state.header);
  const {
    showCreateObservationModal,
    globalAnalysisObservations,
    companyAnalysisObservations,
    isCreatingObservation
  } = useSelector((state: Store) => state.companyAnalysisObservation);

  const [observation, setObservation] = useState<string>();
  const [globalAnalysisObservationId, setGlobalAnalysisObservationId] = useState<string | undefined>();

  const companyId = company._id;

  const closeModal = () => {
    dispatch(companyAnalysisObservationSlice.setShowCreateObservationModal(false));

    form.resetFields();

    setObservation(undefined);
    setGlobalAnalysisObservationId(undefined);
  };

  const onChangeObservation = (ids: string[]) => {
    const id = ids.length === 0 ? undefined : ids[ids.length - 1];
    const globalStockingParameter = globalAnalysisObservations.find((item: GlobalAnalysisObservation) => item.key === id);

    if (!globalStockingParameter?._id) {
      setObservation(id);

      form.setFieldsValue({
        observation: id,
      });

      return;
    }

    setObservation(globalStockingParameter.key);
    setGlobalAnalysisObservationId(globalStockingParameter._id);

    form.setFieldsValue({
      observation: globalStockingParameter.key,
    });
  };

  const saveObservation = () => {
    if (!observation) {
      return;
    }

    const body: CompanyAnalysisObservation = {
      companyId,
      key: observation,
      globalAnalysisObservationId,
    };

    const props = {
      body,
      onSuccess: closeModal,
    };

    dispatch(companyAnalysisObservationSlice.createCompanyObservation(props));
  };

  const renderAnalysisObservation = () => {
    const globalObservations = globalAnalysisObservations.filter(globalObservation =>
      !companyAnalysisObservations.some(companyObservation => companyObservation.key === globalObservation.key)
    );

    return (
      <Form.Item
        name='observation'
        label={t('analysisObservations.observation')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvSelect
          id='analysis-observation-dropdown'
          theme='light'
          showSearch
          mode='tags'
          autoFocus
          placeholder={t('analysisObservations.observation')}
          onChange={onChangeObservation}
          removeIcon={<Icon name='close' />}
          dropdownMatchSelectWidth={false}
          filterOption={filterOptionSelect}
          value={observation}
        >
          {globalObservations.map((stockingParameter: GlobalAnalysisObservation) =>
            <Option key={stockingParameter.key} value={stockingParameter.key}>{t(`analysisObservations.types.${stockingParameter.key}`)}</Option>)
          }
        </LrvSelect>
      </Form.Item>
    );
  };

  return (
    <LrvModal
      theme='light'
      title={t('analysisObservations.newObservation')}
      open={showCreateObservationModal}
      destroyOnClose={true}
      okButtonProps={{
        id: 'submit-analysis-observation',
        htmlType: 'submit',
        form: 'form',
        loading: isCreatingObservation,
        disabled: !observation,
      }}
      onOk={saveObservation}
      okText={t('analysisObservations.accept')}
      cancelText={t('analysisObservations.cancel')}
      onCancel={closeModal}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form-analysis-observation'
        layout='vertical'
      >
        <Form.Item>
          <LrvText theme={theme} text={t('analysisObservations.description')} />
        </Form.Item>

        {renderAnalysisObservation()}
      </LrvForm>
    </LrvModal>
  );
};