import cx from 'classnames';
import { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RadioChangeEvent, Select, Tabs, Tag } from 'antd';

import { Stocking } from '../interfaces';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { formatLongDateWithZone } from '../../../utils/date';
import { filterOptionSelect } from '../../../utils/select';
import { LrvTabs } from '../../../common/components/LrvTabs/LrvTabs';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import TinyLabel from '../../../common/components/TinyLabel/TinyLabel';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../../common/components/LrvInput/LrvInput';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import CleanButton from '../../../common/components/buttons/CleanButton';
import TagWrapper from '../../../common/components/TagWrapper/TagWrapper';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { LrvButton } from '../../../common/components/LrvButton/LrvButton';
import { LrvDivider } from '../../../common/components/LrvDivider/LrvDivider';
import { getUnitPhaseTypeFromAnalysis } from '../../../helpers/units.helpers';
import { calcDensity, getUnitDensity } from '../../../helpers/stocking.helpers';
import { getStartDate } from '../../../common/components/charts/ShadedPlot/helpers';
import { LrvRadioGroup } from '../../../common/components/LrvRadioGroup/LrvRadioGroup';
import { CONTAINER_LABEL, stockingPhaseTypes, stockingSearchTypes, stockingStatuses, unitStatuses } from '../../../config/commons';

import styles from './OtherStockings.module.scss';
import { StockingComparision, Unit } from './interfaces';
import * as otherStockingsSlice from './otherStockingsSlice';
import * as stockingAnalysisSlice from './stockingAnalysisSlice';

const { TabPane } = Tabs;
const { Option } = Select;

const compareStockingCurrentTab = {
  SEARCH_TANK: 'SEARCH_TANK',
  SEARCH_GLOBAL: 'SEARCH_GLOBAL',
  SEARCH_MATURATION: 'SEARCH_MATURATION',
};

export const OtherStockings = (props: { stockingDefault?: Stocking }) => {
  const { stockingDefault } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const {
    skip,
    limit,
    total,
    tanks: tanksList,
    modules: modulesList,
    campuses: campusesList,
    stockings,
    isFetchStockings,
    maturations,
    maturationCodes,
    searchType,
  } = useSelector((state: Store) => state.otherStockings);

  const {
    showOtherStockingsModal,
    listStockingsIds,
    listStockingsName,
    enabledStockings,
    listStockingsDensity,
  } = useSelector((state: Store) => state.stockingAnalysis);
  const { phaseType: phaseTypeSelected } = useSelector((state: Store) => state.header);

  const [campus, setCampus] = useState<Unit | undefined>(stockingDefault?.campusId);
  const [module, setModule] = useState<string | undefined>(stockingDefault?.moduleId._id);
  const [tank, setTank] = useState<string | undefined>(stockingDefault?.tankId._id);
  const [maturationId, setMaturationId] = useState<string | undefined>(stockingDefault?.maturationId?._id);
  const [maturationCodeId, setMaturationCodeId] = useState<string | undefined>(stockingDefault?.maturationCode);
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentTab, setCurrentTab] = useState<string>(compareStockingCurrentTab.SEARCH_TANK);

  const [currentPage, setCurrentPage] = useState(((skip / limit) + 1) || 1);

  useEffect(() => {
    return (() => {
      dispatch(stockingAnalysisSlice.setListStockingsIds([]));
      dispatch(stockingAnalysisSlice.setListStockingsName([]));
      dispatch(stockingAnalysisSlice.setEnabledStockings([]));
      dispatch(stockingAnalysisSlice.setListStockingsDensity([]));
    });
  }, [dispatch]);

  useEffect(() => {
    if (!stockingDefault?._id || !showOtherStockingsModal) {
      return;
    }

    const unitPhaseType = getUnitPhaseTypeFromAnalysis(stockingDefault.phaseType);
    const paramsCampus = { companyId: stockingDefault.companyId, phaseType: unitPhaseType };

    dispatch(otherStockingsSlice.fetchCampuses(paramsCampus));
    dispatch(otherStockingsSlice.fetchMaturations(stockingDefault.companyId));
    dispatch(otherStockingsSlice.fetchModules(stockingDefault.campusId._id));
    dispatch(otherStockingsSlice.fetchTanks(stockingDefault.campusId._id, stockingDefault.moduleId._id));

    const paramsStocking = { page: 1, companyId: stockingDefault.companyId, campusId: stockingDefault.campusId._id, moduleId: stockingDefault.moduleId._id, tankId: stockingDefault.tankId._id, phase: stockingDefault.phaseType };
    dispatch(otherStockingsSlice.fetchStockings(paramsStocking));
  }, [dispatch, stockingDefault?.companyId, stockingDefault?.campusId?._id, stockingDefault?.moduleId?._id, stockingDefault?.tankId?._id, stockingDefault?.phaseType, showOtherStockingsModal]);

  const cleanStockings = () => {
    dispatch(stockingAnalysisSlice.setListStockingsIds([]));
    dispatch(stockingAnalysisSlice.setListStockingsName([]));
    dispatch(stockingAnalysisSlice.setEnabledStockings([]));
    dispatch(stockingAnalysisSlice.setListStockingsDensity([]));
  };

  const renderCleanStockings = () => {
    if (listStockingsIds.length === 0) {
      return null;
    }

    return (
      <div className={styles.clean}>
        <CleanButton onClick={() => cleanStockings()} />
      </div>
    );
  };

  const renderLegendsSelecteds = () => {
    return <div className={styles.legendsModal}>
      {listStockingsIds.map((stockingsIds: string, index: number) => {
        if (index === 0) {
          return null;
        }

        return <TagWrapper key={index} text={listStockingsName[index]} onClose={() => {
          const names: string[] = listStockingsName.filter((_, i) => i !== index);
          const enables: boolean[] = enabledStockings.filter((_, i) => i !== index);
          const densities: string[] = listStockingsDensity.filter((_, i) => i !== index);

          const ids = listStockingsIds.filter((id) => {
            const value = id !== stockingsIds;
            return value;
          });

          if (ids.length === 1) {
            cleanStockings();
          } else {
            dispatch(stockingAnalysisSlice.setEnabledStockings(enables));
            dispatch(stockingAnalysisSlice.setListStockingsIds(ids));
            dispatch(stockingAnalysisSlice.setListStockingsName(names));
            dispatch(stockingAnalysisSlice.setListStockingsDensity(densities));
          }
        }} />;
      })}
    </div>;
  };

  const onChangeCampus = (value: string) => {
    const campus = campusesList.find((campus) => campus._id === value);

    if (!campus?._id) {
      return;
    }

    setTank('');
    setModule('');
    setCampus(campus);
    setCurrentPage(1);

    const params = { page: 1, companyId: stockingDefault?.companyId, campusId: campus._id, phase: phaseTypeSelected };
    dispatch(otherStockingsSlice.fetchModules(campus._id));
    dispatch(otherStockingsSlice.fetchTanks(campus._id));
    dispatch(otherStockingsSlice.fetchStockings(params));
  };

  const onChangeModule = (value: string) => {
    setTank('');
    setModule(value);
    setCurrentPage(1);

    const params = { page: 1, companyId: stockingDefault?.companyId, campusId: campus?._id, moduleId: value, phase: phaseTypeSelected };
    dispatch(otherStockingsSlice.fetchTanks(campus?._id, value));
    dispatch(otherStockingsSlice.fetchStockings(params));
  };

  const onChangeTank = (props: { value?: string; page: number }) => {
    const { value, page } = props;
    setTank(value);
    setCurrentPage(page);

    const params = { page, companyId: stockingDefault?.companyId, campusId: campus?._id, moduleId: module, tankId: value, phase: phaseTypeSelected };
    dispatch(otherStockingsSlice.fetchStockings(params));
  };

  const onChangeMaturation = (value: string) => {
    setMaturationCodeId('');
    setMaturationId(value);
    setCurrentPage(1);

    const params = { page: 1, companyId: stockingDefault?.companyId, maturationId: value, phase: phaseTypeSelected };
    dispatch(otherStockingsSlice.fetchMaturationCodes(value));
    dispatch(otherStockingsSlice.fetchStockings(params));
  };

  const onChangeMaturationCode = (props: { value?: string; page: number }) => {
    const { value, page } = props;
    setMaturationCodeId(value);
    setCurrentPage(page);

    const params = { page, companyId: stockingDefault?.companyId, maturationId, maturationCode: value, phase: phaseTypeSelected };
    dispatch(otherStockingsSlice.fetchStockings(params));
  };

  const columns: ColumnsType<StockingComparision> = [
    {
      key: 1,
      title: t('stockings.stocking'),
      dataIndex: 'name',
      width: '36%',
    }, {
      key: 2,
      title: t('analysis.analysis'),
      dataIndex: 'analysisCount',
      width: '14%',
    }, {
      key: 3,
      title: t('stockings.state'),
      dataIndex: 'status',
      width: '18%',
      render: (_, record: StockingComparision) => {
        return (
          <div className={styles.containerTag}>
            <TinyLabel text={record.status ? t('stockings.status.active') : t('stockings.status.finished')} className={record.status ? styles.stockingActive : styles.stockingFinished} />
          </div>
        );
      },
    }, {
      key: 4,
      title: t('stockings.startDate'),
      dataIndex: 'startDate',
      width: '26%',
    }, {
      key: 5,
      dataIndex: 'name',
      width: '6%',
      render: (_, record: StockingComparision) => {
        let showDefaultTag = false;
        for (let index = 0; index < listStockingsIds.length; index++) {
          if (listStockingsIds[index] === record._id) {
            showDefaultTag = true;
          }
        }

        if (record._id === stockingDefault?._id) {
          return (
            <div className={styles.stockingSelected}>
              <Icon name='check' />
            </div>
          );
        }

        if (showDefaultTag) {
          return (
            <Icon name='check' className={styles.checkIcon} />
          );
        }

        return null;
      },
    }];

  const stockingData: StockingComparision[] = stockings ? stockings.map((stocking: Stocking) => {
    const startDate = getStartDate(stocking);

    return {
      _id: stocking._id,
      name: stocking.name,
      startDate: formatLongDateWithZone({ date: startDate }),
      analysisCount: stocking.analysisCount,
      status: stocking.status === stockingStatuses.ACTIVE,
      naupliusNumber: stocking.naupliusNumber,
      juvenilesNumber: stocking.juvenilesNumber,
      growOutNumber: stocking.growOutNumber,
      litersNumber: stocking.litersNumber,
      hectares: stocking.hectares,
      cubicMeters: stocking.cubicMeters,
      phaseType: stocking.phaseType,
    };
  }) : [];

  const renderFiltersByTank = () => {
    return (
      <div className={styles.filters}>
        <LrvSelect
          theme='light'
          id='dropdown_campus_others_stocking'
          title={t('stockings.selectCampus')}
          className={styles.select}
          value={campus?.name || undefined}
          showSearch
          placeholder={t('stockings.selectCampus')}
          onChange={onChangeCampus}
          suffixIcon={<Icon name='arrow-down-s' />}
          dropdownMatchSelectWidth={false}
          filterOption={filterOptionSelect}
        >
          {campusesList.map((campus) => {
            return (
              <Option key={campus._id} value={campus._id} label={campus.name}>
                {campus.name}
                {
                  campus.status === unitStatuses.INACTIVE
                  &&
                  <>
                    &nbsp; &nbsp;
                    <Tag color='#e04d00'>{t('campus.inactive')}</Tag>
                  </>
                }
              </Option>
            );
          })}
        </LrvSelect>

        <div className={styles.arrowRightIcon} >
          <Icon name='arrow-right-s' />
        </div>

        <LrvSelect
          id='dropdown_modules_others_stocking'
          theme='light'
          title={t('stockings.selectModule')}
          className={styles.select}
          value={module || undefined}
          showSearch
          placeholder={t('stockings.selectModule')}
          onChange={onChangeModule}
          suffixIcon={<Icon name='arrow-down-s' />}
          disabled={!campus}
          dropdownMatchSelectWidth={false}
          optionFilterProp='children'
          filterOption={filterOptionSelect}
        >
          {modulesList.map((module) => <Option key={module._id} value={module._id}>{module.name}</Option>)}
        </LrvSelect>

        <div className={styles.arrowRightIcon} >
          <Icon name='arrow-right-s' />
        </div>

        <LrvSelect
          id='dropdown_tanks_others_stocking'
          theme='light'
          title={t(`stockings.selectTank.${CONTAINER_LABEL[phaseTypeSelected ?? stockingPhaseTypes.LARVAE]}`)}
          className={styles.select}
          value={tank || undefined}
          showSearch
          placeholder={t(`stockings.selectTank.${CONTAINER_LABEL[phaseTypeSelected ?? stockingPhaseTypes.LARVAE]}`)}
          onChange={(value) => onChangeTank({ value, page: 1 })}
          suffixIcon={<Icon name='arrow-down-s' />}
          disabled={!module}
          dropdownMatchSelectWidth={false}
          optionFilterProp='children'
          filterOption={filterOptionSelect}
        >
          {tanksList.map((tank) => <Option key={tank._id} value={tank._id}>{tank.name}</Option>)}
        </LrvSelect>
      </div>
    );
  };

  const renderFiltersByMaturation = () => {
    return (
      <div className={styles.filters}>
        <LrvSelect
          theme='light'
          title={t('stockings.maturation')}
          id='dropdown_maturation_others_stocking'
          className={styles.select}
          value={maturationId || undefined}
          showSearch
          placeholder={t('stockings.maturation')}
          onChange={onChangeMaturation}
          suffixIcon={<Icon name='arrow-down-s' />}
          dropdownMatchSelectWidth={false}
          optionFilterProp='children'
          filterOption={filterOptionSelect}
        >
          {maturations.map((maturation) => <Option key={maturation._id} value={maturation._id}>{maturation.name}</Option>)}
        </LrvSelect>

        <div className={styles.arrowRightIcon} >
          <Icon name='arrow-right-s' />
        </div>

        <LrvSelect
          id='dropdown_modules_others_stocking'
          title={t('stockings.maturationCode')}
          theme='light'
          className={styles.select}
          value={maturationCodeId || undefined}
          showSearch
          placeholder={t('stockings.maturationCode')}
          onChange={(value) => onChangeMaturationCode({ value, page: 1 })}
          suffixIcon={<Icon name='arrow-down-s' />}
          disabled={!maturationId}
          dropdownMatchSelectWidth={false}
          optionFilterProp='children'
          filterOption={filterOptionSelect}
        >
          {maturationCodes.map((maturation) => <Option key={maturation._id} value={maturation._id}>{maturation.code}</Option>)}
        </LrvSelect>

        <div className={styles.arrowRightIcon} />
        <div className={styles.select} />
      </div>
    );
  };

  const onChangeTypeSearch = (event: RadioChangeEvent) => {
    const value = event.target.value;
    dispatch(otherStockingsSlice.setSearchType(value));
  };

  const renderRadioGroup = () => {
    return (
      <LrvRadioGroup
        title={t('stockings.typeSearch.title')}
        theme='light'
        id='type_search'
        value={searchType}
        onChange={onChangeTypeSearch}
        options={[
          { label: t('stockings.typeSearch.name'), value: stockingSearchTypes.NAME },
          { label: t('stockings.typeSearch.cycle'), value: stockingSearchTypes.CYCLE },
        ]}
        className={styles.radioGroup}
      />
    );
  };

  const searchStocking = (page: number) => {
    const params = {
      page,
      companyId: stockingDefault?.companyId,
      phase: phaseTypeSelected,
      name: searchType === stockingSearchTypes.NAME ? searchValue : undefined,
      code: searchType === stockingSearchTypes.CYCLE ? searchValue : undefined,
    };

    dispatch(otherStockingsSlice.fetchStockings(params));
  };

  const renderSearch = () => {
    return (
      <LrvInput
        theme='light'
        className={styles.search}
        placeholder={t('stockings.searchByName')}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onPressEnter={() => searchStocking(1)}
        suffix={
          <div
            className={styles.suffixIcon}
            onClick={() => searchStocking(1)}
          >
            <Icon
              id='search_icon_button'
              name='search'
            />
          </div>
        }
      />
    );
  };

  const renderInfoSearchByName = () => {
    return (
      <div className={styles.informationSearch}>
        <Icon name='flag-2' className={styles.flag} />

        <LrvText
          className={styles.description}
          theme='light'
          text={t('stockings.infoSearchByName')}
        />
      </div>
    );
  };

  const renderTabs = () => {
    return (
      <LrvTabs
        theme='light'
        activeKey={currentTab}
        onChange={(key) => setCurrentTab(key)}
      >
        <TabPane tab={t(`stockings.searchByTank.${phaseTypeSelected}`)} key={compareStockingCurrentTab.SEARCH_TANK}>
          <div>
            {renderInfoSearchByName()}
            {renderFiltersByTank()}
          </div>
        </TabPane>

        <TabPane tab={t('stockings.searchMaturation')} key={compareStockingCurrentTab.SEARCH_MATURATION}>
          <div>
            {renderInfoSearchByName()}
            {renderFiltersByMaturation()}
          </div>
        </TabPane>

        <TabPane tab={t('stockings.searchGlobal')} key={compareStockingCurrentTab.SEARCH_GLOBAL}>
          {renderRadioGroup()}
          {renderSearch()}
        </TabPane>
      </LrvTabs>
    );
  };

  const renderResults = () => {
    return (
      <div className={styles.separator}>
        <LrvText
          className={styles.result}
          theme='light'
          text={t('stockings.results')}
        />

        <LrvDivider
          theme='light'
          type='horizontal'
          className={styles.divider}
        />
      </div>
    );
  };

  const onChangePage = (page: number) => {
    setCurrentPage(page);

    switch (currentTab) {
      case compareStockingCurrentTab.SEARCH_TANK:
        onChangeTank({ value: tank, page });
        break;

      case compareStockingCurrentTab.SEARCH_MATURATION:
        onChangeTank({ value: tank, page: currentPage });
        onChangeMaturationCode({ value: maturationCodeId, page });
        break;

      case compareStockingCurrentTab.SEARCH_GLOBAL:
        searchStocking(page);
        break;
    }
  };

  const getStockingDensity = (stocking: Stocking) => {
    return calcDensity(stocking) + ' ' + getUnitDensity(stocking);
  };

  return (
    <LrvModal
      theme='light'
      title={null}
      open={showOtherStockingsModal}
      className={cx(styles.modalSelectStockings, 'modalCompareStocking')}
      closable={false}
      destroyOnClose={true}
      onCancel={() => dispatch(stockingAnalysisSlice.setShowOtherStockingsModal(false))}
      footer={[
        <LrvButton
          id='view_chart_button'
          className={styles.button}
          type='primary'
          onClick={() => dispatch(stockingAnalysisSlice.setShowOtherStockingsModal(false))}
        >
          {t('stockings.viewCurves')}
        </LrvButton>
      ]}
      width={640}
    >
      <div className={styles.row}>
        <LrvText
          theme='light'
          className={styles.information}
          text={t('shadedplot.information')}
        />
        {renderCleanStockings()}
      </div>

      {renderLegendsSelecteds()}
      {renderTabs()}
      {renderResults()}

      <LrvTable
        theme='light'
        className={styles.table}
        style={{ cursor: listStockingsIds.length === 5 ? 'not-allowed' : 'default' }}
        columns={columns}
        dataSource={stockingData}
        loading={isFetchStockings}
        rowClassName={cx(styles.rows, listStockingsIds.length === 5 ? styles.disabledRows : '')}
        pagination={{
          showSizeChanger: false,
          size: 'default',
          showQuickJumper: false,
          current: currentPage,
          total: total,
          pageSize: limit,
          onChange: onChangePage,
        }}
        scroll={{ y: 240 }}
        size='small'
        onRow={(record) => {
          return {
            onClick: () => {
              if (!stockingDefault?._id || listStockingsIds.includes(record._id) || stockingDefault?._id === record._id) {
                return;
              }

              if (listStockingsIds.length === 0) {
                const stockingsId = [stockingDefault?._id, record._id];
                const stockingsName = [stockingDefault?.name, record.name];
                const stockingsDensity = [getStockingDensity(stockingDefault), getStockingDensity(record)];

                dispatch(stockingAnalysisSlice.setListStockingsIds(stockingsId));
                dispatch(stockingAnalysisSlice.setListStockingsName(stockingsName));
                dispatch(stockingAnalysisSlice.setListStockingsDensity(stockingsDensity));

              } else {
                const stockingsId = [...listStockingsIds, record._id];
                const stockingsName = [...listStockingsName, record.name];
                const stockingsDensity = [...listStockingsDensity, getStockingDensity(record)];

                if (listStockingsIds.length < 5) {
                  dispatch(stockingAnalysisSlice.setListStockingsIds(stockingsId));
                  dispatch(stockingAnalysisSlice.setListStockingsName(stockingsName));
                  dispatch(stockingAnalysisSlice.setListStockingsDensity(stockingsDensity));
                }
              }

              if (enabledStockings.length === 0) {
                const enableStockingList = [true, true];
                dispatch(stockingAnalysisSlice.setEnabledStockings(enableStockingList));
              } else {
                const enableStockingList = [...enabledStockings, true];
                dispatch(stockingAnalysisSlice.setEnabledStockings(enableStockingList));
              }
            }
          };
        }}
      />
    </LrvModal>
  );
};
