import cx from 'classnames';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState, } from 'react';

import Data from '../Data';
import Footer from '../Footer';
import Header from '../Header';
import LegendStocking from '../LegendStocking';
import { DataSource } from '../../home/interfaces';
import { getMaxStage } from '../../Analysis/helpers';
import { Store } from '../../../state/store.interfaces';
import { fetchStocking } from '../../Sowings/sowingsSlice';
import * as headerSlice from '../../AppHeader/headerSlice';
import { stockingPhaseTypes } from '../../../config/commons';
import { charactersToSeparate } from '../../../utils/strings';
import Content from '../../../common/components/Content/Content';
import { fetchAnalysis, fetchGrowthDelta } from '../../home/homeSlice';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { Legend } from '../../../common/components/charts/ShadedPlot/Legend';
import { fetchReferenceCurve } from '../../Sowings/Analysis/stockingAnalysisSlice';
import D3ShadedPlot from '../../../common/components/charts/ShadedPlot/D3ShadedPlot';
import { groupStagesByLunarPhase, incrementLastItem } from '../../../helpers/lunar-age';
import FactorKChartD3 from '../../../common/components/charts/FactorKChart/FactorKChartD3';
import GrowthDeltaChart from '../../../common/components/charts/ShadedPlot/GrowthDeltaChart';
import { calcDensity, getLabelAxisX, getParameter, getUnitDensity } from '../../../helpers/stocking.helpers';
import { groupPointsByStage, metricsStatuses, typesChart, typeParam, sortDeltaData, injectPigmentationToPoints, colorsPoints, colorsStroke, isReferenceCurveMetric, lineColor, estimators } from '../../../common/components/charts/ShadedPlot/helpers';

import './SowingComparisionPdf.scss';
import styles from './SowingComparisionPdf.module.scss';

let chart: D3ShadedPlot | GrowthDeltaChart | FactorKChartD3 | null;

type TParams = {
  stockingId: string; stockingsId: string;
  stockingsName: string; accessToken: string;
  language: string; dataSource: string;
  parameter: string; scale: string;
  firstStage: string; lastStage: string;
  movingAverage: string;
  estimator: string;
};

export const StockingComparisonPdf = ({ match }: RouteComponentProps<TParams>) => {
  const {
    stockingId, stockingsId, stockingsName, accessToken,
    language, dataSource, parameter, scale,
    firstStage, lastStage,
    movingAverage,
    estimator,
  } = match.params;

  const showMovingAverage = movingAverage !== '-1';
  const showDerivative = estimators.DERIVATIVE === estimator;

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const ids = stockingsId ? stockingsId.split(charactersToSeparate) : [];
  const names = stockingsName ? stockingsName.split(charactersToSeparate) : [];

  const [listStockingsIds] = useState<string[]>(ids);
  const [listStockingsName] = useState<string[]>(names);
  const [maxStage, setMaxStage] = useState<number>(0);
  const refChartMain = useRef<HTMLDivElement>(null);

  const metrics = useSelector((state: Store) => state.metrics);
  const { company } = useSelector((state: Store) => state.header);
  const stocking = useSelector((state: Store) => state.stockings.selectedStocking);
  const { referenceCurves } = useSelector((state: Store) => state.stockingAnalysis);

  const width = 1680 - 96;
  const circleLegendHeight = 34;
  const height = listStockingsIds.length === 0 ? 950 - 22 - circleLegendHeight : 920 - 22 - circleLegendHeight;
  const backgroundColor = 'white';

  const invalidDataSource = dataSource === 'undefined';

  const sortStockings = useCallback(() => {
    const stockingGroupById = groupBy(metrics.analysis, 'sowingId');
    const dataSource: DataSource[] = [];
    const dataSourceSort: DataSource[] = [];

    for (const key in stockingGroupById) {
      if (Object.prototype.hasOwnProperty.call(stockingGroupById, key)) {
        const index = listStockingsIds.indexOf(key);
        const stockingName = listStockingsName[index];
        const enabled = true;
        const points = stockingGroupById[key];
        const newPoints = injectPigmentationToPoints({ points, parameter });

        const stockingGroup: DataSource = { id: key, name: stockingName, enabled: enabled, show: true, points: newPoints, avgPoint: [] };
        dataSource.push(stockingGroup);
      }
    }

    if (listStockingsIds.length === 0) {
      const data = dataSource.filter(value => value.id === stockingId)[0];
      data.enabled = true;
      data.avgPoint = groupPointsByStage({ points: data.points, parameter });
      dataSourceSort.push(data);
      return dataSourceSort;
    }

    for (let index = 0; index < listStockingsIds.length; index++) {
      const data: DataSource = dataSource.filter(stocking => stocking.id === listStockingsIds[index])[0];
      if (data) {
        data.avgPoint = groupPointsByStage({ points: data.points, parameter });
        dataSourceSort.push(data);
      } else {
        const id = listStockingsIds[index];
        const name = listStockingsName[index];
        const enabled = true;
        const stockingGroup: DataSource = { id: id, name: name, enabled: enabled, avgPoint: [], points: [] };
        dataSourceSort.push(stockingGroup);
      }
    }

    return dataSourceSort;
  }, [listStockingsIds, listStockingsName, metrics.analysis, stockingId]);

  useEffect(() => {
    return () => {
      chart = null;
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchStocking({ id: stockingId, token: accessToken, fetchPresignedUrl: false }));
  }, [dispatch, stockingId, accessToken]);

  useEffect(() => {
    if (!stocking.companyId) {
      return;
    }

    dispatch(headerSlice.fetchCompany(stocking.companyId, accessToken));
  }, [dispatch, stocking?.companyId, accessToken]);

  useEffect(() => {
    if (invalidDataSource) {
      return;
    }

    if (!stocking?._id || !isReferenceCurveMetric(dataSource)) {
      return;
    }

    const props = { _id: dataSource, token: accessToken };
    dispatch(fetchReferenceCurve(props));
  }, [dispatch, stocking?._id, dataSource, invalidDataSource]);

  useEffect(() => {
    if (!company._id || !stocking.phaseType) {
      return;
    }

    const maxStage = getMaxStage(company, stocking.phaseType);
    setMaxStage(maxStage);
  }, [company, stocking.phaseType]);

  useEffect(() => {
    if (!stocking.companyId || !maxStage) {
      return;
    }

    const stockings: string[] = listStockingsIds.length === 0 ? [stockingId] : listStockingsIds;
    dispatch(fetchAnalysis({ stockingsId: stockings, showLoading: true, token: accessToken, maxStage, companyId: stocking.companyId }));
  }, [dispatch, stockingId, listStockingsIds, listStockingsIds.length, stocking.companyId, accessToken, maxStage]);

  useEffect(() => {
    if (!stocking.companyId || parameter === typeParam.PIGMENTATION) {
      return;
    }

    if (parameter === typeParam.GROWTH_DELTA) {
      dispatch(fetchGrowthDelta(listStockingsIds.length > 0 ? listStockingsIds : stocking._id, accessToken));
    }
  }, [dispatch, stockingId, parameter, stocking.companyId, accessToken, listStockingsIds, stocking._id, stocking.phaseType]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n, i18n.language]);

  useEffect(() => {
    const getData = () => {
      if (parameter === typeParam.GROWTH_DELTA) {
        return metrics.growthDelta;
      }

      switch (dataSource) {
        case metricsStatuses.GROWTH_DELTA:
          return [];

        default:
          return referenceCurves.values;
      }
    };

    const showChartMain = () => {
      const data = getData();
      const stockingGroupList = sortStockings();

      if (chart || !refChartMain.current) {
        return;
      }

      const colorFillRect = backgroundColor;
      const colorLine = lineColor.light;

      if (parameter === typeParam.GROWTH_DELTA) {
        const enabledStocking = listStockingsName.map(() => true);
        const deltaStockings = sortDeltaData(data, '', listStockingsName, enabledStocking);

        chart = new GrowthDeltaChart({
          colorFillRect,
          colorLine,
          companyData: company,
          container: refChartMain.current,
          dataMetric: deltaStockings,
          firstStage: parseFloat(firstStage),
          height,
          lastStage: parseFloat(lastStage),
          parameter: typeParam.GROWTH_DELTA,
          phaseType: stocking.phaseType,
          scale,
          showLabels: deltaStockings.length === 1,
          typeChart: typesChart.STOCKINGS,
          width,
          movingAverage: parseInt(movingAverage),
          showMovingAverage,
        });
        return;
      }

      if (stockingGroupList.length === 0) {
        return;
      }

      if (parameter === typeParam.FACTOR_K) {
        const firstLunarAge = metrics.analysis[0].resultData.lunarAge;
        let lunarPhaseStages = groupStagesByLunarPhase({ lunarAge: firstLunarAge, minDay: parseFloat(firstStage), maxDay: parseFloat(lastStage) });
        lunarPhaseStages = incrementLastItem(lunarPhaseStages);

        chart = new FactorKChartD3({
          colorFillRect,
          colorLine,
          container: refChartMain.current,
          dataMetric: data,
          dataSource: stockingGroupList,
          firstStage: parseFloat(firstStage),
          height,
          lastStage: parseFloat(lastStage),
          scale,
          typeMetric: dataSource,
          width,
          colorsPoints,
          colorsStroke,
          movingAverage: parseInt(movingAverage),
          lunarPhaseStages,
          showMovingAverage,
          showDerivative,
        });
        return;
      }

      chart = new D3ShadedPlot({
        colorFillRect,
        colorLine,
        companyData: company,
        container: refChartMain.current,
        dataMetric: data,
        dataSource: stockingGroupList,
        firstStage: parseFloat(firstStage),
        height,
        lastStage: parseFloat(lastStage),
        parameter,
        phaseType: stocking.phaseType,
        scale,
        showLabels: listStockingsName.length === 0,
        typeChart: typesChart.STOCKINGS,
        typeMetric: dataSource,
        width,
        colorsPoints,
        colorsStroke,
        movingAverage: parseInt(movingAverage),
        showMovingAverage,
      });
    };

    if (metrics.analysis.length > 0 && company._id) {
      showChartMain();
    }
  }, [stocking.phaseType, metrics.analysis, sortStockings, dataSource, scale, parameter, firstStage, lastStage, width, height, metrics.growthDelta, referenceCurves.values, company._id, listStockingsName, movingAverage, showMovingAverage]);

  if (stocking.phaseType !== stockingPhaseTypes.ADULT && referenceCurves.values.length === 0 && metrics.growthDelta.length === 0 && parameter !== typeParam.PIGMENTATION) {
    return <div className={styles.spinner}>
      <DotSpinner />
    </div>;
  }

  if (metrics.analysis.length === 0 && parameter === typeParam.PIGMENTATION) {
    return <div className={styles.spinner}>
      <DotSpinner />
    </div>;
  }

  if (stocking.phaseType === stockingPhaseTypes.ADULT && (!invalidDataSource && isReferenceCurveMetric(dataSource) && referenceCurves.values.length === 0)) {
    return <div className={styles.spinner}>
      <DotSpinner />
    </div>;
  }

  const getTitle = () => {
    if (listStockingsName.length > 0) {
      return company?.name;
    }

    let campusName = stocking?.campusId?.name.toLowerCase();
    campusName = campusName.charAt(0).toUpperCase() + campusName.slice(1);
    const title = `${company?.name} - ${campusName}`;

    if (stocking.stockingBindingCode) {
      return `${title} - ${t('stockings.pdf.stockingBindingCode')}`;
    }

    return title;
  };

  const getSubTitle = () => {
    let subtitle = '';

    const density = calcDensity(stocking);
    const unitDensity = getUnitDensity(stocking);

    if (listStockingsName.length > 0) {
      subtitle = t('stockings.pdf.subtitle');
    } else {
      let maturationName = stocking.maturationId.name.toLowerCase();
      maturationName = maturationName.charAt(0).toUpperCase() + maturationName.slice(1);
      subtitle = t('stockings.pdf.stocking') + ' ' + stocking.name + ' - ' + t('stockings.pdf.maturation') + ' ' + maturationName + ' - ' + t('stockings.pdf.density') + ' ' + density + ' ' + unitDensity;
    }

    return subtitle;
  };

  const renderRightAxisYParameter = () => {
    return (
      <div className={styles.labelAxisY}> {t('production.window.derivative')} </div>
    );
  };

  const renderPointLegends = () => {
    if (listStockingsName.length > 0) {
      return (
        <div className={styles.pointLegends}>
          <div className={styles.labelAxisY}>{getParameter({ parameter, stockingPhaseType: stocking.phaseType })}</div>
          <LegendStocking legendList={listStockingsName} />
          <div className={cx(styles.labelAxisY, styles.transparent)}>{getParameter({ parameter, stockingPhaseType: stocking.phaseType })}</div>
        </div>
      );
    }

    return (
      <div className={styles.pointLegends}>
        <div className={styles.labelAxisY}>{getParameter({ parameter, stockingPhaseType: stocking.phaseType })}</div>
        <Legend color='black' parameter={parameter} phaseTypeSelected={stocking.phaseType} />
        {
          showDerivative ?
            renderRightAxisYParameter() :
            <div className={cx(styles.labelAxisY, styles.transparent)}>{getParameter({ parameter, stockingPhaseType: stocking.phaseType })}</div>
        }
      </div>
    );
  };

  const renderReport = () => {
    const title = getTitle();
    const subtitle = getSubTitle();

    return <div className={cx(styles.containerMain, 'stockingComparisionPdf')}>
      <Header
        title={title}
        subtitle={subtitle}
        stockingBindingCode={stocking.stockingBindingCode}
      />

      <div className={styles.rowData}>
        <Data
          stockingPhaseType={stocking.phaseType}
          dataSource={dataSource}
          parameter={parameter}
          scale={scale}
          language={language}
          referenceName={referenceCurves.name}
        />
      </div>

      {renderPointLegends()}

      <div className={styles.rowData}>
        <Content
          headerClassName={styles.headerPadding}
          titleClassName={styles.titleContent}
          noPadding
          style={{ backgroundColor: backgroundColor }}
        >
          <div ref={refChartMain} className={styles.chart}> </div>
          <div className={styles.labelAxisX}>{getLabelAxisX(stocking.phaseType)}</div>
        </Content>
      </div>
      <Footer />
    </div>;
  };

  return renderReport();
};