import cx from 'classnames';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Stocking as IStocking } from '../interfaces';
import { Store } from '../../../state/store.interfaces';
import { formatMillion } from '../../../utils/strings';
import Icon from '../../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../../utils/select';
import { getUserSession } from '../../../utils/userSession';
import { formatLongDateWithZone } from '../../../utils/date';
import { UserSession } from '../../../common/interfaces/auth';
import { stockingPhaseTypes, THEME } from '../../../config/commons';
import IconButton from '../../../common/components/buttons/IconButton';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { LrvAvatarPhase } from '../../../common/components/LrvAvatarPhase/LrvAvatarPhase';
import { AutoEllipsisTooltip } from '../../../common/components/AutoEllipsisTooltip/AutoEllipsisTooltip';
import { fetchMaturationsInfo, fetchStockingInformation, setShowStockingInformationModal } from '../Analysis/stockingAnalysisSlice';

import styles from './OriginsMultiphase.module.scss';
import * as multiphaseSlice from './multiphaseSlice';
import { Stocking, StockingByPhase } from './interfaces';

interface Props {
  rowClassName?: string;
  theme?: 'dark' | 'light';
  stockingDefault?: IStocking;
  showStockingDropdown?: boolean;
}

const { Option } = Select;

export const OriginsMultiphase = (props: Props) => {
  const { rowClassName, theme, stockingDefault, showStockingDropdown = false } = props;
  const isLightTheme = theme === THEME.LIGHT;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const userSession: UserSession = getUserSession();
  const { multiphase } = useSelector((state: Store) => state.stockingMultiphase);
  const { stockings } = multiphase;

  const getClassName = (phaseType: string) => {
    switch (phaseType) {
      case 'larvae':
      default:
        return styles.larvae;

      case 'juvenile':
        return styles.juvenile;

      case 'growOut':
        return styles.growOut;
    }
  };

  const getDensity = (stocking: StockingByPhase) => {
    if (stocking.phaseType === stockingPhaseTypes.LARVAE) {
      return stocking.unit;
    }

    return `${t('stockings.anim')}/${stocking.unit}`;
  };

  const onStockingChange = (props: { stockingId: string; phaseType: string }) => {
    const { phaseType, stockingId } = props;

    if (!stockingDefault?._id) {
      return;
    }

    switch (phaseType) {
      case stockingPhaseTypes.LARVAE:
        dispatch(multiphaseSlice.fetchMultiphase({ stockingId: stockingDefault._id, phaseType: stockingDefault.phaseType, juvenileStockingId: stockings.juvenile?.stockingId, larvaeStockingId: stockingId }));
        break;

      case stockingPhaseTypes.JUVENILE:
        dispatch(multiphaseSlice.fetchMultiphase({ stockingId: stockingDefault._id, phaseType: stockingDefault.phaseType, juvenileStockingId: stockingId }));
        break;
    }
  };

  const renderStockingDropdown = (stocking: StockingByPhase) => {
    if (!showStockingDropdown || stocking.phaseType === stockingPhaseTypes.ADULT || !stocking.stockings?.length || stocking.stockings.length === 1) {
      return (
        <AutoEllipsisTooltip containerClassName={styles.stockingNameContainer} text={stocking.name} theme={theme} />
      );
    }

    return (
      <LrvSelect
        theme={theme}
        id='dropdown_stocking'
        showSearch
        className={styles.select}
        value={stocking.stockingId || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        placeholder={t('clientDashboard.userCompany')}
        optionFilterProp='children'
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
        onChange={(value: string) => onStockingChange({ stockingId: value, phaseType: stocking.phaseType })}
      >
        {stocking.stockings.map((stocking: Stocking, index: number) =>
          <Option key={index} value={stocking._id}>{stocking.name}</Option>
        )}
      </LrvSelect>
    );
  };

  const renderIconPhase = (phaseType: string) => {
    switch (phaseType) {
      case 'larvae':
      default:
        return (
          <LrvAvatarPhase
            phaseType={stockingPhaseTypes.LARVAE}
            avatarSize={24}
            iconSize={20}
          />
        );
      case 'juvenile':
        return (
          <LrvAvatarPhase
            phaseType={stockingPhaseTypes.JUVENILE}
            avatarSize={24}
            iconSize={20}
          />
        );
      case 'growOut':
        return (
          <LrvAvatarPhase
            phaseType={stockingPhaseTypes.ADULT}
            avatarSize={24}
            iconSize={20}
          />
        );
    }
  };

  const renderShapes = (shape: 'circle' | 'triangle') => {
    switch (shape) {
      case 'circle':
        return (
          <div
            className={styles.circle}
            style={{
              backgroundColor: isLightTheme ? '#a8b7ec' : '#d9d9d9',
            }}
          />
        );

      case 'triangle':
        return (
          <div
            className={styles.triangle}
            style={{
              borderBottomColor: isLightTheme ? '#a8b7ec' : '#d9d9d9',
            }}
          />
        );
    }
  };

  const renderMoreInfoIcon = (stockingId: string) => {
    return (
      <IconButton
        id='info_icon'
        type='link'
        onClick={() => {
          dispatch(setShowStockingInformationModal(true));
          dispatch(fetchStockingInformation({ stockingId, companyId: userSession.companyId }));
          dispatch(fetchMaturationsInfo({ stockingId }));
        }}
        iconName='information'
        tooltipText={t('production.filters.stockingInfo')}
        placement='right'
      />
    );
  };

  const renderRow = (props: { key: string; stocking?: StockingByPhase; showShape?: boolean; shape?: 'circle' | 'triangle'; }) => {
    const { key, stocking, showShape = false, shape = 'circle' } = props;

    if (!stocking) {
      return null;
    }

    return (
      <tr key={stocking.stockingId} className={cx(isLightTheme ? styles.bodyLight : styles.bodyDark, styles.body, rowClassName, getClassName(`${key}`))}>
        <td>
          {renderIconPhase(key)}
        </td>
        <td>
          <AutoEllipsisTooltip text={stocking.companyName} theme={theme} />
        </td>
        <td>
          <AutoEllipsisTooltip text={stocking.unitName} theme={theme} />
        </td>
        <td>
          <AutoEllipsisTooltip text={stocking.moduleName} theme={theme} />
        </td>
        <td>
          <AutoEllipsisTooltip text={stocking.tankName} theme={theme} />
        </td>
        <td>
          {/* If exist hoursOffset, it means that this component it is rendering in MultiphasePdf */}
          <AutoEllipsisTooltip text={formatLongDateWithZone({ date: stocking.startDate })} theme={theme} />
        </td>
        <td className={styles.stocking}>
          {showShape && renderShapes(shape)}
          {renderStockingDropdown(stocking)}
          {renderMoreInfoIcon(stocking.stockingId)}
        </td>
        <td>
          <AutoEllipsisTooltip text={`${formatMillion(stocking.density)} ${getDensity(stocking)}`} theme={theme} />
        </td>
      </tr>
    );
  };

  const renderRows = (props: { key: string; stockings?: StockingByPhase[] }) => {
    const { key, stockings } = props;
    const items = [];

    if (!stockings) {
      return null;
    }

    for (let index = 0; index < stockings.length; index++) {
      const stocking = stockings[index];
      const item = renderRow({ key, stocking, showShape: true, shape: index % 2 === 0 ? 'circle' : 'triangle' });
      items.push(item);
    }

    return items;
  };

  const renderTableBody = () => {
    return [
      renderRows({ key: 'larvae', stockings: stockings.larvaes }),
      renderRow({ key: 'larvae', stocking: stockings.larvae }),
      renderRows({ key: 'juvenile', stockings: stockings.juveniles }),
      renderRow({ key: 'juvenile', stocking: stockings.juvenile }),
      renderRow({ key: 'growOut', stocking: stockings.growOut }),
    ];
  };

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.header}>
            <th>{t('stockings.selectPhase').toUpperCase()}</th>
            <th>{t('clients.company').toUpperCase()}</th>
            <th>{t('stockings.campus').toUpperCase()}</th>
            <th>{t('stockings.module').toUpperCase()}</th>
            <th>{t('stockings.tank').toUpperCase()}</th>
            <th>
              <AutoEllipsisTooltip text={t('stockings.stockingIn').toUpperCase()} theme={theme} />
            </th>
            <th>{t('stockings.stocking').toUpperCase()}</th>
            <th>{t('stockings.density').toUpperCase()}</th>
          </tr>
        </thead>

        <tbody>
          {renderTableBody()}
        </tbody>
      </table>
    </div>
  );
};