import { Dropdown, Menu } from 'antd';
import { useEffect } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import { commonOptions } from '../../../config/commons';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { changeHeader } from '../../AppHeader/headerSlice';
import { LrvTag } from '../../../common/components/LrvTag/LrvTag';
import IconButton from '../../../common/components/buttons/IconButton';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import ActionButton from '../../../common/components/buttons/ActionButton';

import styles from './AnalysisObservation.module.scss';
import { CompanyAnalysisObservation } from './interfaces';
import { NewObservationForm } from './NewObservationForm';
import { EditObservationForm } from './EditObservationForm';
import * as companyAnalysisObservationSlice from './companyAnalysisObservationSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export const AnalysisObservation = (props: Props) => {
  const { theme = getCurrentTheme() } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { companyAnalysisObservations, isLoadingCompanyObservation } = useSelector((state: Store) => state.companyAnalysisObservation);

  useEffect(() => {
    dispatch(changeHeader({ title: 'analysisObservations.title' }));
    dispatch(companyAnalysisObservationSlice.fetchCompanyObservations());
  }, [dispatch]);

  const menuItemsStockingOptions = (record: CompanyAnalysisObservation) => {
    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case commonOptions.EDIT:
              dispatch(companyAnalysisObservationSlice.setShowEditObservationModal(true));
              dispatch(companyAnalysisObservationSlice.setCompanyAnalysisObservation(record));
              return;
          }
        }}
      >
        <Menu.Item id='menu_option_edit' key={commonOptions.EDIT} className={styles.menuItemOptions}>
          <Icon name='edit' theme={theme} className={styles.icon} />
          <span>{t('stockings.edit')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  const renderStatusTag = (record: CompanyAnalysisObservation) => {
    if (record.active) {
      // if return null, table will display an "-" in the empty cell
      return <div></div>;
    }

    const id = `tag_${record._id}`;
    return (
      <LrvTag
        id={id}
        type='info'
        className={styles.statusTag}
      >
        {t('analysisObservations.inactive')}
      </LrvTag>
    );
  };

  const columns: ColumnsType<CompanyAnalysisObservation> = [
    {
      key: 1,
      title: t('analysisObservations.observation'),
      width: '55%',
      render: (_, record: CompanyAnalysisObservation) => {
        if (record.globalAnalysisObservationId) {
          return t(`analysisObservations.types.${record.key}`);
        }

        return record.key;
      },
    },
    {
      key: 3,
      dataIndex: 'active',
      width: '40%',
      render: (_, record: CompanyAnalysisObservation) => {
        return renderStatusTag(record);
      },
    },
    {
      key: 3,
      width: '5%',
      render: (_, record: CompanyAnalysisObservation) => {
        return (
          <Dropdown
            overlay={() => menuItemsStockingOptions(record)}
            trigger={['click']}
            placement='bottomRight'
          >
            <IconButton
              onClick={(e) => e.stopPropagation()}
              iconName='more-2'
            />
          </Dropdown>
        );
      },
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.alignButtonRight}>
        <ActionButton
          id='add_button'
          type='primary'
          icon={<Icon name='add' />}
          onClick={() => {
            dispatch(companyAnalysisObservationSlice.setShowCreateObservationModal(true));
            dispatch(companyAnalysisObservationSlice.fetchGlobalObservations());
          }}
        >
          {t('stockingParameter.create')}
        </ActionButton>
      </div>

      <LrvTable
        columns={columns}
        dataSource={companyAnalysisObservations}
        loading={isLoadingCompanyObservation}
        scroll={{ y: '' }}
        theme={theme}
        size='small'
        pagination={{
          size: 'default',
        }}
      />
      
      <NewObservationForm theme='light' />
      <EditObservationForm theme='light' />
    </div>
  );
};
