import seedrandom from 'seedrandom';

import { Analysis } from '../../Analysis/interfaces';
import { getCurrentElementHeight, getCurrentElementWidth } from '../../../utils/dimensions';
import { DEFAULT_STAGE_MAX, roundTwoDecimals, stockingPhaseTypes } from '../../../config/commons';

import { DataSource, Point } from './interfaces';

export const chartOptions = {
  WEIGHT: 'WEIGHT',
  BIOMASS_KG: 'BIOMASS_KG',
  BIOMASS_LB: 'BIOMASS_LB',
};

export const FEEDING_STRATEGY = {
  HIGH: 1.05,
  NORMAL: 1,
  LOW: 0.95,
};

interface HeightProps {
  filters: React.RefObject<HTMLDivElement>;
}

interface WidthProps {
  inputsContainer?: React.RefObject<HTMLDivElement>;
}

export const getHeightOfTheOtherElements = (props: HeightProps) => {
  const { filters } = props;

  const headerHeight = 64;
  const extraHeight = 136;

  const value = getCurrentElementHeight(filters) + headerHeight + extraHeight;
  return value;
};

export const getWidthOfTheOtherElements = (props: WidthProps) => {
  const { inputsContainer } = props;
  const elementWidth = inputsContainer ? getCurrentElementWidth(inputsContainer) : 0;

  const extraWidth = 90;
  let sidebarWidth = 0;

  if (window.innerWidth > 950) {
    sidebarWidth = 80;
  }

  if (window.innerWidth > 1420) {
    sidebarWidth = 240;
  }

  return sidebarWidth + extraWidth + elementWidth;
};

export const getAverageWeight = (phaseType: string, averageWeight: number) => {
  if (phaseType === stockingPhaseTypes.LARVAE) {
    return roundTwoDecimals(averageWeight);
  }

  return roundTwoDecimals(averageWeight / 1000);
};

export const getNumberTicks = (props: { firstStage: number; lastStage: number; predictions: Analysis[]; isExcluding: boolean; }) => {
  const { firstStage, lastStage, predictions, isExcluding } = props;
  const SEVEN_DAYS = 7;
  const daysAfterLastStage = predictions.length * SEVEN_DAYS;

  if (!isExcluding) {
    const stagesDiff = (lastStage - firstStage) / 2;
    return stagesDiff < DEFAULT_STAGE_MAX ? stagesDiff : 16;
  }

  const stagesDiff = (lastStage - firstStage) - daysAfterLastStage;
  return stagesDiff < DEFAULT_STAGE_MAX ? stagesDiff : 16;
};

function randomNormal (rng: seedrandom.PRNG, weightPredicted: number, sigma: number) {
  const u1 = rng();
  const u2 = rng();
  const z0 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2); // Transformed from Box-Muller
  return (z0 * sigma) + weightPredicted;
}

export const getRandomWeights = (params: { dataSource: DataSource; predictionSelected: Point; size?: number }) => {
  const { dataSource, predictionSelected, size = 150 } = params;
  const { predictions } = dataSource;
  const rng = seedrandom('1');

  const prediction = predictions.find(item => item.inputData.stage === predictionSelected.x);
  
  let uniformity = predictionSelected.uniformity;
  let predictedWeight = predictionSelected.y * 1000;

  if (prediction) {
    uniformity = prediction.resultData.uniformity;
    predictedWeight = prediction.resultData.averageWeight;
  }

  const cv = 100 - uniformity;
  const sigma = (cv / 100) * predictedWeight;

  return Array.from({ length: size }, () => randomNormal(rng, predictedWeight, sigma));
};

export const classifyGrowOutSizes = (ranges: number[], individualWeights: number[]) => {
  const constantToConvert = 1000;
  const length = ranges.length + 1;
  const values: number[] = Array(length).fill(0);

  for (const weight of individualWeights) {
    const convertedWeight = weight / constantToConvert;
    let rangeIndex = -1;

    for (const value of ranges) {
      rangeIndex++;

      // eslint-disable-next-line max-depth
      if (convertedWeight < value) {
        values[rangeIndex]++;
        break;
      }

      // eslint-disable-next-line max-depth
      if (rangeIndex === ranges.length - 1 && convertedWeight > value) {
        values[rangeIndex + 1]++;
        break;
      }
    }
  }

  return values;
};
