import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../../common/components/LrvInput/LrvInput';
import { LrvSwitch } from '../../../common/components/LrvSwitch/LrvSwitch';
import { LrvText } from '../../../common/components/LrvText/LrvText';

import styles from './EditObservationForm.module.scss';
import { CompanyAnalysisObservation } from './interfaces';
import * as companyAnalysisObservationSlice from './companyAnalysisObservationSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export const EditObservationForm = (props: Props) => {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const { company } = useSelector((state: Store) => state.header);
  const {
    showEditObservationModal,
    isEditingObservation,
    companyAnalysisObservation,
  } = useSelector((state: Store) => state.companyAnalysisObservation);

  const [active, setActive] = useState<boolean>();
  const [observation, setObservation] = useState<string>();

  const companyId = company._id;

  const getObservationKey = () => {
    if (!companyAnalysisObservation?.key) {
      return '';
    }

    if (companyAnalysisObservation?.globalAnalysisObservationId) {
      return t(`analysisObservations.types.${companyAnalysisObservation.key}`);
    }

    return companyAnalysisObservation.key;
  };

  useEffect(() => {
    if (!companyAnalysisObservation?._id) {
      return;
    }

    setObservation(companyAnalysisObservation.key);
    setActive(companyAnalysisObservation.active);

    form.setFieldsValue({
      active,
      observation: getObservationKey(),
    });
  }, [form, companyAnalysisObservation, showEditObservationModal]);

  const closeModal = () => {
    dispatch(companyAnalysisObservationSlice.setShowEditObservationModal(false));

    form.resetFields();

    setObservation(undefined);
  };

  const updateObservation = () => {
    if (!observation) {
      return;
    }

    const body: CompanyAnalysisObservation = {
      _id: companyAnalysisObservation?._id,
      companyId,
      active,
      key: observation,
    };

    const props = {
      body,
      onSuccess: closeModal,
    };

    dispatch(companyAnalysisObservationSlice.updateCompanyObservation(props));
  };

  const renderAnalysisObservation = () => {
    return (
      <Form.Item
        name='observation'
        label={t('analysisObservations.observation')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvInput
          id='stocking-parameters-input'
          theme={theme}
          value={observation}
          placeholder={t('stockingParameter.parameter')}
          onChange={(e) => setObservation(e.target.value)}
          readOnly={!!companyAnalysisObservation?.globalAnalysisObservationId}
        />
      </Form.Item>
    );
  };

  const renderSwitch = () => {
    return (
      <Form.Item>
        <div className={styles.switch}>
          <div className={styles.status}>
            <LrvText text={t('analysisObservations.active')} theme={theme} />
          </div>

          <LrvSwitch
            theme={theme}
            checked={active}
            onChange={(value) => setActive(value)}
          />
        </div>
      </Form.Item>
    );
  };

  return (
    <LrvModal
      theme={theme}
      title={t('analysisObservations.editObservation')}
      open={showEditObservationModal}
      destroyOnClose={true}
      okButtonProps={{
        id: 'submit-analysis-observation',
        htmlType: 'submit',
        form: 'form',
        loading: isEditingObservation,
        disabled: !observation,
      }}
      onOk={updateObservation}
      okText={t('analysisObservations.accept')}
      cancelText={t('analysisObservations.cancel')}
      onCancel={closeModal}
      className={styles.editObservation}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form-analysis-observation'
        layout='vertical'
      >
        {renderSwitch()}
        {renderAnalysisObservation()}
      </LrvForm>
    </LrvModal>
  );
};