import i18next from 'i18next';
import { clone } from 'lodash';

import { getMoonPhase } from '../../../helpers/lunar-age';
import { applyThousandsSeparator } from '../../../utils/strings';
import { formatDayMonthYear, formatLocaleHour } from '../../../utils/date';
import { calcAvgGrowth, calcDensity, getUnitDensity } from '../../../helpers/stocking.helpers';
import { animalDaysUnit, DEFAULT_DAYS_TO_INITIAL_STAGE, roundLength, roundWeight, stockingPhaseTypes } from '../../../config/commons';
import { LrvFirstQuarterMoon, LrvFullMoon, LrvLastQuarterMoon, LrvNewMoon } from '../../../common/components/LrvMoonPhase/LrvMoonPhase';

import { getAlgorithmUsed, getStageLabel } from './helper.extraInformation';
import { AnalysisDetail, Analysis, User, AnalysisDetailCustom } from './interfaces';

export const getSampleTitle = (code: string | undefined) => {
  if (code) {
    return i18next.t('detail.sampleAnalyzed') + ' ' + code;
  }

  return i18next.t('detail.sampleAnalyzed');
};

export const getGeneticCode = (props: { analysis: Analysis; }) => {
  const { analysis } = props;

  const codes = analysis.inputData?.maturationId?.codes;
  const maturationCode = analysis.sowingId?.maturationCode;

  if (!codes || !maturationCode) {
    return '-';
  }


  const geneticCode = codes.find((code) => code._id === maturationCode);
  return geneticCode?.code || '';
};

const renderDensity = (props: { analysis: Analysis; }) => {
  const { analysis } = props;

  if (!analysis.sowingId) {
    return '-';
  }

  const density = calcDensity(analysis.sowingId);
  const densityUnit = getUnitDensity(analysis.sowingId);
  return `${applyThousandsSeparator(density)} ${densityUnit}`;
};

const renderStageEdited = (props: { analysis: Analysis; }) => {
  const { analysis } = props;

  if (!analysis.sowingId || analysis.phaseType !== stockingPhaseTypes.LARVAE) {
    return '-';
  }

  return analysis.sowingId?.originalCustomStage?.key ? i18next.t('confirm.yes') : i18next.t('confirm.no');
};

export const getLabelForKey = (props: { phaseType: string; field: AnalysisDetailCustom; lunarAge?: number; }) => {
  const { phaseType, field, lunarAge = 0 } = props;

  const dictType = phaseType === stockingPhaseTypes.LARVAE ? 'larvae' : 'juvenile';

  switch (field) {
    case 'algorithm':
      return i18next.t('detail.extraInformation.algorithm');

    case 'averageLength':
      return i18next.t('analysis.resultData.averageLength');

    case 'averageWeight':
      return i18next.t('analysis.resultData.averageWeight');

    case 'avgGrowth':
      return i18next.t('analysis.resultData.avgGrowth');

    case 'campusName':
      return i18next.t('campus.campus');

    case 'companyName':
      return i18next.t('detail.extraInformation.company');

    case 'containerName':
      return i18next.t('campus.tank');

    case 'editedSample':
      return i18next.t('detail.extraInformation.editedSample');

    case 'editedStage':
      return getStageLabel(phaseType);

    case 'endHour':
      return i18next.t('detail.extraInformation.endHour');

    case 'larvaePerGram':
      return i18next.t('analysis.resultData.larvaePerGram');

    case 'maturationCode':
      return i18next.t('stockings.maturationCode');

    case 'maturationName':
      return i18next.t('analysis.inputData.maturation');

    case 'moduleName':
      return i18next.t('campus.module');

    case 'offline':
      return i18next.t('detail.extraInformation.offline');

    case 'phone':
      return i18next.t('detail.extraInformation.phone');

    case 'sample':
      return i18next.t('analysis.resultData.sample');

    case 'stage':
      return i18next.t(`analysis.inputData.${dictType}Stage`);

    case 'startHour':
      return i18next.t('detail.extraInformation.startHour');

    case 'stockingDateEdited':
      return i18next.t('detail.extraInformation.dateEdited');

    case 'stockingDensity':
      return i18next.t('detail.extraInformation.density');

    case 'stockingStageEdited':
      return i18next.t('detail.extraInformation.stageEdited');

    case 'uniformityLength':
      return `${i18next.t('detail.length')} ${i18next.t('analysis.resultData.uniformity')}`;

    case 'uniformityWeight':
      return `${i18next.t('detail.weight')} ${i18next.t('analysis.resultData.uniformity')}`;

    case 'userName':
      return i18next.t('users.user');

    case 'variationCoefficientWeight':
      return i18next.t('analysis.resultData.variationCoefficient');

    case 'variationCoefficientLength':
      return i18next.t('analysis.resultData.variationCoefficientLength');

    case 'larvaeNumber': {
      switch (phaseType) {
        case stockingPhaseTypes.LARVAE:
        default:
          return i18next.t('analysis.resultData.larvaeNumber');

        case stockingPhaseTypes.JUVENILE:
          return i18next.t('analysis.resultData.juvenileNumber');

        case stockingPhaseTypes.ADULT:
          return i18next.t('analysis.resultData.shrimpsNumber');
      }
    }

    case 'moonPhase':
      return getMoonPhaseLabelByLunarAge({ lunarAge });

    default:
      return '';
  }
};

export const getValueForKey = (props: { analysis: Analysis; field: AnalysisDetailCustom; user: User; daysToInitialStage?: number }) => {
  const { analysis, field, user, daysToInitialStage = DEFAULT_DAYS_TO_INITIAL_STAGE } = props;

  const analysisPhaseType = analysis.phaseType;
  const dictType = analysisPhaseType === stockingPhaseTypes.LARVAE ? 'larvae' : 'juvenile';
  const days = analysis.inputData?.stage + (daysToInitialStage - 1);

  const uniformityWeight = analysis.resultData.uniformity;
  const uniformityLength = 100 - analysis.resultData.variationCoefficientLength;

  const stage = analysisPhaseType === stockingPhaseTypes.LARVAE ? `${animalDaysUnit.PL} ${analysis.inputData?.stage} - ${days} ${i18next.t('analysis.days')}` : `${analysis.inputData?.stage} ${i18next.t('analysis.days')}`;

  switch (field) {
    case 'algorithm':
      return getAlgorithmUsed(analysis.type);

    case 'averageLength':
      return roundLength({ value: analysis.resultData?.averageLength });

    case 'averageWeight':
      return roundWeight({ value: analysis.resultData?.averageWeight });

    case 'avgGrowth': {
      const avgGrowth = calcAvgGrowth({ averageWeight: analysis.resultData.averageWeight, stage: analysis.inputData.stage, phaseType: analysis.phaseType, stockingWeight: analysis.sowingId?.weight });
      return avgGrowth;
    }

    case 'campusName':
      return analysis.campusName?.toUpperCase() || '-';

    case 'companyName':
      return analysis.nameCompany;

    case 'containerName':
      return analysis.sowingId?.tankName || '-';

    case 'editedSample':
      return analysis.inferenceData?.larvaeModifiedList ? i18next.t('confirm.yes') : i18next.t('confirm.no');

    case 'editedStage':
      return analysis.hasManualStage ? i18next.t('confirm.yes') : i18next.t('confirm.no');

    case 'endHour':
      return `${formatDayMonthYear(analysis.completedDate)} - ${formatLocaleHour(analysis.completedDate)}`;

    case 'larvaePerGram':
      return `${analysis.resultData.larvaePerGram}`;

    case 'maturationCode':
      return getGeneticCode({ analysis });

    case 'maturationName':
      return analysis.inputData?.maturationId?.name || '-';

    case 'moduleName':
      return analysis.sowingId?.moduleName || '-';

    case 'offline':
      return analysis.isOffline ? i18next.t('confirm.yes') : i18next.t('confirm.no');

    case 'phone':
      return analysis.deviceModel || '-';

    case 'sample':
      return `${analysis.resultData?.larvaeNumber} ${i18next.t(`analysis.resultData.${dictType}Sample`)} / ${analysis.inputData?.sampleWeight}`;

    case 'stage':
      return stage;

    case 'startHour':
      return `${formatDayMonthYear(analysis.createdAt)} - ${formatLocaleHour(analysis.createdAt)}`;

    case 'stockingDateEdited':
      return analysis.sowingId?.originalStartDate ? i18next.t('confirm.yes') : i18next.t('confirm.no');

    case 'stockingDensity':
      return renderDensity({ analysis });

    case 'stockingStageEdited':
      return renderStageEdited({ analysis });

    case 'uniformityLength':
      return `${uniformityLength.toFixed(2)} %`;

    case 'uniformityWeight':
      return `${uniformityWeight.toFixed(2)} %`;

    case 'userName':
      return `${user.firstName} ${user.lastName}`;

    case 'variationCoefficientWeight':
      return `${analysis.resultData?.variationCoefficient?.toFixed(2)} %`;

    case 'variationCoefficientLength':
      return `${analysis.resultData?.variationCoefficientLength?.toFixed(2)} %`;

    case 'larvaeNumber':
      return analysis.resultData.larvaeNumber;


    case 'moonPhase': {
      return getMoonPhaseIcon(analysis.resultData?.lunarAge);
    }

    default:
      return '';
  }
};

export const getMoonPhaseLabel = (props: { moonPhase: string }) => {
  const { moonPhase } = props;

  switch (moonPhase) {
    case 'NEW_MOON':
      return i18next.t('detail.moonPhase.newMoon');

    case 'FIRST_QUARTER_MOON':
      return i18next.t('detail.moonPhase.firstQuarterMoon');

    case 'LAST_QUARTER_MOON':
      return i18next.t('detail.moonPhase.lastQuarterMoon');

    case 'FULL_MOON':
      return i18next.t('detail.moonPhase.fullMoon');

    default:
      return '';
  }
};

const getMoonPhaseLabelByLunarAge = (props: { lunarAge: number }) => {
  const { lunarAge } = props;
  return getMoonPhaseLabel({ moonPhase: `${getMoonPhase(lunarAge)}` });
};

const getMoonPhaseIcon = (lunarAge: number) => {
  const moonPhase = getMoonPhase(lunarAge);

  switch (moonPhase) {
    case 'NEW_MOON':
      return <LrvNewMoon />;

    case 'FIRST_QUARTER_MOON':
      return <LrvFirstQuarterMoon />;

    case 'LAST_QUARTER_MOON':
      return <LrvLastQuarterMoon />;

    case 'FULL_MOON':
      return <LrvFullMoon />;
  }
};

export const getDataDetail = (props: { analysis: Analysis; fields: AnalysisDetailCustom[]; user: User; daysToInitialStage?: number }) => {
  const { analysis, fields, user, daysToInitialStage } = props;

  const analysisDetailList: AnalysisDetail[] = [];

  fields.forEach((field) => {
    const item: AnalysisDetail = {
      label: getLabelForKey({ lunarAge: analysis.resultData.lunarAge, phaseType: analysis.phaseType, field }),
      value: getValueForKey({ analysis, field, user, daysToInitialStage }),
    };

    analysisDetailList.push(item);
  });

  return analysisDetailList;
};

export const commonDetailCustom: AnalysisDetailCustom[] = [
  'sample', 'maturationName', 'stage',
  'averageWeight', 'variationCoefficientWeight', 'uniformityWeight',
  'averageLength', 'variationCoefficientLength', 'uniformityLength',
  'campusName', 'moduleName', 'containerName',
  'userName', 'phone', 'companyName',
  'stockingDensity', 'editedSample', 'editedStage',
  'algorithm', 'startHour', 'endHour',
  'offline', 'maturationCode', 'stockingDateEdited',
  'moonPhase'
];

export const commonDetailDefault: AnalysisDetailCustom[] = [
  'sample', 'stage',
  'averageWeight', 'variationCoefficientWeight', 'uniformityWeight',
  'averageLength', 'variationCoefficientLength', 'uniformityLength',
  'campusName', 'moduleName', 'startHour',
];

export const larvaeDetailCustom: AnalysisDetailCustom[] = [...commonDetailCustom, 'larvaePerGram', 'stockingStageEdited'];
export const juvenileDetailCustom: AnalysisDetailCustom[] = [...commonDetailCustom, 'avgGrowth'];
export const growOutDetailCustom: AnalysisDetailCustom[] = [...commonDetailCustom, 'avgGrowth'];

export const larvaeDetailDefault: AnalysisDetailCustom[] = clone(commonDetailDefault);
larvaeDetailDefault.splice(1, 0, 'larvaePerGram');
export const juvenileDetailDefault: AnalysisDetailCustom[] = clone(commonDetailDefault);
juvenileDetailDefault.splice(1, 0, 'maturationName');
export const growOutDetailDefault: AnalysisDetailCustom[] = clone(commonDetailDefault);
growOutDetailDefault.splice(1, 0, 'avgGrowth');

export const getKeysAnalysisCustom = (props: { phaseType: string; fields: AnalysisDetailCustom[] }) => {
  const { phaseType, fields } = props;

  if (fields.length === 0) {
    switch (phaseType) {
      case stockingPhaseTypes.LARVAE:
      default: {
        const otherKeys = larvaeDetailCustom.filter(element => !larvaeDetailDefault.includes(element));
        return {
          keysSelected: larvaeDetailDefault,
          otherKeys,
        };
      }

      case stockingPhaseTypes.JUVENILE: {
        const otherKeys = juvenileDetailCustom.filter(element => !juvenileDetailDefault.includes(element));
        return {
          keysSelected: juvenileDetailDefault,
          otherKeys,
        };
      }

      case stockingPhaseTypes.ADULT: {
        const otherKeys = growOutDetailCustom.filter(element => !growOutDetailDefault.includes(element));
        return {
          keysSelected: growOutDetailDefault,
          otherKeys,
        };
      }
    }
  }

  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default: {
      const otherKeys = larvaeDetailCustom.filter(element => !fields.includes(element));

      return {
        keysSelected: fields,
        otherKeys,
      };
    }

    case stockingPhaseTypes.JUVENILE: {
      const otherKeys = juvenileDetailCustom.filter(element => !fields.includes(element));

      return {
        keysSelected: fields,
        otherKeys,
      };
    }

    case stockingPhaseTypes.ADULT: {
      const otherKeys = growOutDetailCustom.filter(element => !fields.includes(element));

      return {
        keysSelected: fields,
        otherKeys,
      };
    }
  }
};
