import jsPDF from 'jspdf';
import i18next from 'i18next';
import autoTable, { RowInput } from 'jspdf-autotable';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sortByName } from '../../../utils/sort';
import { Company } from '../../AppHeader/interfaces';
import larviaLogo from '../../../assets/powered-by.png';
import { getUserSession } from '../../../utils/userSession';
import { formatLongDateWithZone } from '../../../utils/date';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { getStartDateStocking } from '../../../helpers/stocking.helpers';
import { stockingStatuses, unitStatuses } from '../../../config/commons';
import { getUnitPhaseTypeFromAnalysis } from '../../../helpers/units.helpers';
import { SurvivalRateState, SurvivalRatePayload, SurvivalRateHistogramPayload, Stocking, SurvivalRateFiltersPayload, Container, Module, Campus } from '../../Sowings/interfaces';
import { SURVIVAL_RATE_URL, SURVIVAL_RATE_HISTOGRAM_URL, MODULES_URL, TANKS_URL, CAMPUS_URL, COMPANIES_URL, STOCKINGS_HARVESTED_URL } from '../../../config/config.api';

import { chartType, initialFiltersState } from './helpers';

const initialState: SurvivalRateState = {
  global: {
    _id: '',
    survivalAvg: 0,
    sum: 0,
    species: '',
    totalN: 0,
    type: '',
  },
  company: {
    _id: '',
    survivalAvg: 0,
    sum: 0,
    species: '',
    totalN: 0,
    type: '',
    companyId: ''
  },
  survivalRate: [],
  survivalRateHistogram: {
    frequencies: [],
    limits: [],
  },
  isLoadingStockingPdf: false,
  campuses: [],
  modules: [],
  tanks: [],
  isLoading: false,
  tabSelected: chartType.WEEKLY,
  filters: initialFiltersState,
};

export const survivalRateSlice = createSlice({
  name: 'survivalRate',
  initialState,
  reducers: {
    setSurvivalRate: (state: SurvivalRateState, action: PayloadAction<SurvivalRatePayload>) => {
      state.global = action.payload.global;
      state.company = action.payload.company;
      state.survivalRate = action.payload.survivalRate;
    },

    setIsLoading: (state: SurvivalRateState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setIsLoadingStockingPdf: (state: SurvivalRateState, action: PayloadAction<boolean>) => {
      state.isLoadingStockingPdf = action.payload;
    },

    setCampuses: (state: SurvivalRateState, action: PayloadAction<Campus[]>) => {
      state.campuses = action.payload;
    },

    setModules: (state: SurvivalRateState, action: PayloadAction<Module[]>) => {
      state.modules = action.payload;
    },

    setTanks: (state: SurvivalRateState, action: PayloadAction<Container[]>) => {
      state.tanks = action.payload;
    },

    setTabSelected: (state: SurvivalRateState, action: PayloadAction<string>) => {
      state.tabSelected = action.payload;
    },

    setSurvivalRateHistogram: (state: SurvivalRateState, action: PayloadAction<SurvivalRateHistogramPayload>) => {
      state.survivalRateHistogram = action.payload;
    },

    setSurvivalRateFilters: (state: SurvivalRateState, action: PayloadAction<SurvivalRateFiltersPayload>) => {
      state.filters = action.payload;
    },
  },
});

export const {
  setSurvivalRate,
  setIsLoading,
  setSurvivalRateHistogram,
  setIsLoadingStockingPdf,
  setCampuses,
  setModules,
  setTanks,
  setTabSelected,
  setSurvivalRateFilters,
} = survivalRateSlice.actions;

export const fetchSurvivalRate = (
  params: { fromDate: string; toDate: string; type: string; species: string; companyId?: string; phaseType: string; campusId?: string; moduleId?: string; tankId?: string }
) => async (dispatch: Function) => {

  dispatch(setIsLoading(true));

  try {
    const response = await axios.get<SurvivalRatePayload>(SURVIVAL_RATE_URL, { params });
    dispatch(setSurvivalRate(response.data));
    dispatch(setIsLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchCampuses = (companyId: string, phaseType: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    phaseType,
    companyId: companyId,
    'status[$in]': [unitStatuses.ACTIVE, unitStatuses.INACTIVE],
  };

  try {
    const response = await axios.get<Campus[]>(CAMPUS_URL, { params });
    response.data.sort(sortByName);
    dispatch(setCampuses(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchModules = (campusId: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    campusId: campusId,
    active: true,
  };

  try {
    const response = await axios.get<Module[]>(MODULES_URL, { params });
    response.data.sort(sortByName);
    dispatch(setModules(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchTanks = (moduleId: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    moduleId: moduleId,
    active: true,
  };

  try {
    const response = await axios.get<Container[]>(TANKS_URL, { params });
    response.data.sort(sortByName);
    dispatch(setTanks(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchSurvivalRateHistogram = (
  params: { fromDate: string; toDate: string; companyId?: string; phaseType: string; campusId?: string; moduleId?: string; tankId?: string }
) => async (dispatch: Function) => {

  try {
    const response = await axios.get(SURVIVAL_RATE_HISTOGRAM_URL, { params });
    dispatch(setSurvivalRateHistogram(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchStockingsHarvested = (
  params: { companyId?: string; campusId?: string; moduleId?: string; tankId?: string; phaseType: string; fromDate: string; toDate: string }
) => async (dispatch: Function) => {
  dispatch(setIsLoadingStockingPdf(true));
  const { companyId, campusId, moduleId, tankId, toDate, fromDate, phaseType } = params;
  const userSession = getUserSession();
  const company = companyId ?? userSession.companyId;

  const filterStockings = {
    companyId: company,
    campusId,
    moduleId,
    tankId,
    phaseType: phaseType,
    fromDate: fromDate,
    toDate: toDate,
  };

  try {
    const response = await axios.get(STOCKINGS_HARVESTED_URL, { params: filterStockings });
    if (response.data && response.data?.length > 0) {
      const responseCompany = await axios.get(`${COMPANIES_URL}/${company}`);
      generateStockingsPDF(response.data, responseCompany.data?.name);
    }
  } catch (e) {
    console.log(e?.response);
  }
  dispatch(setIsLoadingStockingPdf(false));
};

function getStockingStatus (status: string) {
  let value = '';

  switch (status) {
    case stockingStatuses.HARVESTED:
      value = i18next.t('survivalRate.stockingsFinished.harvested');
      break;

    case stockingStatuses.DISCARDED:
      value = i18next.t('survivalRate.stockingsFinished.discarded');
      break;

    case stockingStatuses.TRANSFER:
      value = i18next.t('survivalRate.stockingsFinished.transfer');
      break;
  }

  return value;
}

export const generateStockingsPDF = (stockings: Stocking[], companyName: string) => {
  const doc = new jsPDF();

  const head = [[i18next.t('stockings.name'), i18next.t('stockings.startDate'), i18next.t('stockings.endDate'), i18next.t('stockings.state')]];
  const tableRows: RowInput[] | undefined = [];

  stockings.forEach((stocking: Stocking) => {
    const stockingStartDate = getStartDateStocking({ stocking, phaseType: stocking.phaseType });
    const ticketData = [
      stocking.name,
      formatLongDateWithZone({ date: stockingStartDate }),
      formatLongDateWithZone({ date: stocking.endDate }),
      getStockingStatus(stocking.status),
    ];
    tableRows.push(ticketData);
  });

  const width = doc.internal.pageSize.getWidth();
  const headerHeight = 20;

  doc.setFillColor(31, 67, 151);
  doc.rect(0, 0, width, headerHeight, 'F');

  doc.setFontSize(20);
  doc.setTextColor(255, 255, 255);
  doc.text(companyName, 15, 10);
  doc.setFontSize(12);
  doc.text(i18next.t('stockings.title'), 15, 15);

  const imageHeight = 8;
  const imageWidth = 36;
  doc.addImage(larviaLogo, 'PNG', width - 50, (headerHeight - (imageHeight / 2) - (headerHeight / 2)), imageWidth, imageHeight);

  doc.setFontSize(16);

  autoTable(doc, {
    head: head,
    body: tableRows,
    theme: 'grid',
    startY: headerHeight + 5,
    headStyles: { fillColor: [31, 67, 151], fontSize: 9 },
    bodyStyles: { fontSize: 9 }
  });

  doc.save('STOCKINGS.pdf');
};

export const resetSurvivalRate = (props: { company: Company; phaseType: string }) => (dispatch: Function) => {
  const { company, phaseType } = props;

  const unitPhaseType = getUnitPhaseTypeFromAnalysis(phaseType);
  dispatch(setSurvivalRateFilters(initialFiltersState));
  dispatch(fetchCampuses(company._id, unitPhaseType));
};

export default survivalRateSlice.reducer;
