import jsPDF from 'jspdf';
import i18next from 'i18next';
import autoTable, { RowInput } from 'jspdf-autotable';

import { isNumber } from '../utils/validations';
import larviaLogo from '../assets/powered-by.png';
import { formatLongDateWithZone } from '../utils/date';
import { stockingPhaseTypes } from '../config/commons';
import { Analysis } from '../pages/Analysis/interfaces';
import { applyThousandsSeparator } from '../utils/strings';
import { PopulationData, Stocking } from '../pages/Sowings/interfaces';

import { getCurrentStateInfo, getHarvestInfo, getPopulationAnimalsNumber, getPopulationDensity, getPopulationNumber, getPopulationSurvivalRate, getStockingInfo } from './population.helpers';

interface Props {
  populationData: PopulationData[]; companyName: string;
  endDate?: string;
  stocking: Stocking;
  volumeUnit?: string;
  showHarvestInfo: boolean;
  showCurrentStateInfo: boolean;
  lastAnalysis?: Analysis;
}

export const generatePopulationPDF = (props: Props) => {
  const { populationData, companyName, endDate, stocking, volumeUnit, showHarvestInfo, showCurrentStateInfo, lastAnalysis } = props;

  const doc = new jsPDF();

  const width = doc.internal.pageSize.getWidth();
  const headerHeight = 20;
  
  const margins = 28;
  const contentWidth = width - margins;
  const columnWidth = contentWidth * 0.25;

  doc.setFillColor(31, 67, 151);
  doc.rect(0, 0, width, headerHeight, 'F');

  doc.setFontSize(20);
  doc.setTextColor(255, 255, 255);
  doc.text(companyName, 15, 10);
  doc.setFontSize(12);
  doc.text(i18next.t('stockings.populations.modalTitle'), 15, 15);

  const imageHeight = 8;
  const imageWidth = 36;
  doc.addImage(larviaLogo, 'PNG', width - 50, (headerHeight - (imageHeight / 2) - (headerHeight / 2)), imageWidth, imageHeight);
  
  const stockingInfo = getStockingInfo({ stocking });

  autoTable(doc, {
    startY: headerHeight + 5,
    head: [[i18next.t('production.filters.stockingInfo') as string, '', '', '']],
    body: [
      [stockingInfo.stockingName.label, stockingInfo.stockingName.value, stockingInfo.stockingDate.label, stockingInfo.stockingDate.value],
      [stockingInfo.unitName.label, stockingInfo.unitName.value, stockingInfo.sownAnimals.label, stockingInfo.sownAnimals.value],
      [stockingInfo.moduleName.label, stockingInfo.moduleName.value, stockingInfo.volume.label, stockingInfo.volume.value],
      [stockingInfo.containerName.label, stockingInfo.containerName.value, stockingInfo.stockingDensity.label, stockingInfo.stockingDensity.value],
      [stockingInfo.stockingCode.label, stockingInfo.stockingCode.value],
    ],
    theme: 'grid',
    headStyles: { fillColor: [31, 67, 151], textColor: [255, 255, 255] },
    bodyStyles: { fontSize: 9 },
    columnStyles: {
      0: { cellWidth: columnWidth, fontStyle: 'bold' },
      1: { cellWidth: columnWidth },
      2: { cellWidth: columnWidth, fontStyle: 'bold' },
      3: { cellWidth: columnWidth },
    },
  });

  if (showHarvestInfo) {
    const stockingInfo = getHarvestInfo({ stocking, populationData });
    const isLarvaeStocking = stocking.phaseType === stockingPhaseTypes.LARVAE;

    autoTable(doc, {
      head: [[i18next.t('stockings.populations.harvest') as string, '', '', '']],
      body: [
        isLarvaeStocking ?
          [stockingInfo.harvestPlg.label, stockingInfo.harvestPlg.value, stockingInfo.totalBiomass.label, stockingInfo.totalBiomass.value] :
          [stockingInfo.harvestWeight.label, stockingInfo.harvestWeight.value, stockingInfo.totalBiomass.label, stockingInfo.totalBiomass.value],
        [stockingInfo.animalsHarvested.label, stockingInfo.animalsHarvested.value, stockingInfo.biomass.label, stockingInfo.biomass.value],
        [stockingInfo.survival.label, stockingInfo.survival.value],
      ],
      theme: 'grid',
      headStyles: { fillColor: [31, 67, 151], textColor: [255, 255, 255] },
      bodyStyles: { fontSize: 9 },
      columnStyles: {
        0: { cellWidth: columnWidth, fontStyle: 'bold' },
        1: { cellWidth: columnWidth },
        2: { cellWidth: columnWidth, fontStyle: 'bold' },
        3: { cellWidth: columnWidth },
      },
    });
  }

  if (showCurrentStateInfo) {
    const currentStateInfo = getCurrentStateInfo({ stocking, populationData, lastAnalysis });

    autoTable(doc, {
      head: [[i18next.t('stockings.populations.currentState') as string, '']],
      body: [
        [currentStateInfo.lastWeight.label, currentStateInfo.lastWeight.value, currentStateInfo.totalBiomass.label, currentStateInfo.totalBiomass.value],
        [currentStateInfo.lastPopulation.label, currentStateInfo.lastPopulation.value, currentStateInfo.biomass.label, currentStateInfo.biomass.value],
        [currentStateInfo.survival.label, currentStateInfo.survival.value],
      ],
      theme: 'grid',
      headStyles: { fillColor: [31, 67, 151], textColor: [255, 255, 255] },
      bodyStyles: { fontSize: 9 },
      columnStyles: {
        0: { cellWidth: columnWidth, fontStyle: 'bold' },
        1: { cellWidth: columnWidth },
        2: { cellWidth: columnWidth, fontStyle: 'bold' },
        3: { cellWidth: columnWidth },
      },
    });
  }

  const head = [[
    i18next.t('stockings.populations.action'),
    i18next.t('stockings.populations.date'),
    i18next.t('stockings.populations.productionDays'),
    i18next.t('stockings.populations.animalsNumber'),
    i18next.t('stockings.populations.population'),
    `${i18next.t('stockings.populations.density')} (anim/${volumeUnit || '?'})`,
    `${i18next.t('stockings.populations.survival')} (%)`,
  ]];

  const tableRows: RowInput[] | undefined = [];

  populationData.forEach((population: PopulationData) => {
    const animals = getPopulationAnimalsNumber(population);
    const animalsFormatted = animals ? applyThousandsSeparator(animals) : '-';
    
    const currentPopulation = getPopulationNumber({ population });
    const populationFormatted = isNumber(currentPopulation) ? applyThousandsSeparator(currentPopulation) : currentPopulation;

    const density = getPopulationDensity({ population, endDate, stockingStatus: stocking.status });
    const densityFormatted = isNumber(density) ? applyThousandsSeparator(density) : density;

    const ticketData = [
      i18next.t(`stockings.populations.populationType.${population.type}`),
      formatLongDateWithZone({ date: population.populationDate }),
      population.productionDays,
      animalsFormatted,
      populationFormatted,
      densityFormatted,
      getPopulationSurvivalRate({ population, endDate, stockingStatus: stocking.status }),
    ];
    tableRows.push(ticketData);
  });

  if (tableRows.length > 0) {
    autoTable(doc, {
      head: head,
      body: tableRows,
      theme: 'grid',
      headStyles: { fillColor: [31, 67, 151], fontSize: 9 },
      bodyStyles: { fontSize: 9 }
    });
  }

  const title = `${i18next.t('stockings.populations.modalTitle')}.pdf`;
  doc.save(title);
};
