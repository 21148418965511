import i18next from 'i18next';

import { Stocking } from '../../Sowings/interfaces';
import { calcDensity, getUnitDensity } from '../../../helpers/stocking.helpers';
import { Company } from '../../AppHeader/interfaces';

export const getTitle = (props: { stocking: Stocking; companyData: Company }) => {
  const { companyData, stocking } = props;

  let campusName = stocking?.campusId?.name.toLowerCase();
  campusName = campusName.charAt(0).toUpperCase() + campusName.slice(1);

  if (!stocking.stockingBindingCode) {
    return `${companyData?.name} - ${campusName}`;
  }

  return `${companyData?.name} - ${campusName} - ${i18next.t('stockings.pdf.stockingBindingCode')}`;
};

export const getSubTitle = (props: { stocking: Stocking }) => {
  const { stocking } = props;
  const density = calcDensity(stocking);
  const unitDensity = getUnitDensity(stocking);

  let maturationName = stocking.maturationId.name.toLowerCase();
  maturationName = maturationName.charAt(0).toUpperCase() + maturationName.slice(1);
  return `${i18next.t('stockings.pdf.stocking')} ${stocking.name} - ${i18next.t('stockings.pdf.maturation')} ${maturationName} - ${i18next.t('stockings.pdf.density')} ${density} ${unitDensity}`;
};

export const calcTop = (page: number) => {
  const height = 1187;
  return (height * (page - 1)) + (height / 2);
};