import { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Stocking } from '../interfaces';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { stockingStatuses } from '../../../config/commons';
import { formatLongDateWithZone } from '../../../utils/date';
import TinyLabel from '../../../common/components/TinyLabel/TinyLabel';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import { LrvInput } from '../../../common/components/LrvInput/LrvInput';
import { getStartDate } from '../../../common/components/charts/ShadedPlot/helpers';

import styles from './StockingsByContainer.module.scss';
import * as stockingAnalysisSlice from './stockingAnalysisSlice';
import { StockingByContainer } from './interfaces';

export const StockingsByContainer = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { showStockingsByContainerModal, stockings, stockingSelected, searchStocking, isLoadingFetchStockings } = useSelector((state: Store) => state.stockingAnalysis);

  const [search, setSearch] = useState('');
  const [newStockingSelected, setNewStockingSelected] = useState('');

  useEffect(() => {
    if (stockingSelected?._id) {
      setNewStockingSelected(stockingSelected?._id);
    }
  }, [stockingSelected]);

  const stockingFilters = searchStocking === '' ? stockings : stockings.length > 0 ? stockings.filter((stocking) => stocking.name.toLowerCase().includes(searchStocking.toLowerCase())) : [];

  const stockingData: StockingByContainer[] = stockingFilters.map((stocking: Stocking) => {
    const startDate = getStartDate(stocking);

    return {
      _id: stocking._id,
      name: stocking.name,
      startDate: formatLongDateWithZone({ date: startDate }),
      analysisCount: stocking.analysisCount,
      status: stocking.status === stockingStatuses.ACTIVE,
    };
  });

  const columns: ColumnsType<StockingByContainer> = [
    {
      key: 1,
      title: t('stockings.stocking').toUpperCase(),
      dataIndex: 'name',
      width: '40%',
    }, {
      key: 2,
      title: t('analysis.analysis').toUpperCase(),
      dataIndex: 'analysisCount',
      width: '14%',
    }, {
      key: 4,
      title: t('stockings.startDate').toUpperCase(),
      dataIndex: 'startDate',
      width: '18%',
    }, {
      key: 3,
      dataIndex: 'status',
      width: '18%',
      render: (_, record: StockingByContainer) => {
        return (
          <TinyLabel
            text={record.status ? t('stockings.status.active').toLowerCase() : t('stockings.status.finished').toLowerCase()}
            className={record.status ? styles.stockingActive : styles.stockingFinished}
          />
        );
      },
    }, {
      key: 5,
      dataIndex: 'name',
      width: '6%',
      render: (_, record: StockingByContainer) => {
        if (record._id === newStockingSelected) {
          return (
            <Icon name='focus-3' className={styles.icon} />
          );
        }

        return null;
      },
    }
  ];

  const getRowClassName = (record: StockingByContainer) => {
    return record._id === newStockingSelected ? styles.rowSelected : '';
  };

  return (
    <LrvModal
      theme='light'
      className={styles.modal}
      title={t('production.stockingModal.title')}
      open={showStockingsByContainerModal}
      destroyOnClose={true}
      onOk={() => {
        dispatch(stockingAnalysisSlice.setListStockingsIds([]));
        dispatch(stockingAnalysisSlice.setListStockingsName([]));
        dispatch(stockingAnalysisSlice.setEnabledStockings([]));

        dispatch(stockingAnalysisSlice.fetchStocking({ stockingId: newStockingSelected }));
        setSearch('');
        dispatch(stockingAnalysisSlice.setSearchStocking(''));
        dispatch(stockingAnalysisSlice.setShowStockingsByContainerModal(false));
        dispatch(stockingAnalysisSlice.setStockings([]));
      }}
      onCancel={() => {
        setSearch('');
        dispatch(stockingAnalysisSlice.setSearchStocking(''));
        dispatch(stockingAnalysisSlice.setShowStockingsByContainerModal(false));
        dispatch(stockingAnalysisSlice.setStockings([]));
      }}
      maskStyle={{ backgroundColor: '#00000080' }}
      width={640}
      okText={t('production.stockingModal.seeCurves')}
      cancelText={t('production.stockingModal.cancel')}
    >
      <div className={styles.searchContainer}>
        <LrvInput
          theme='light'
          size='small'
          className={styles.search}
          placeholder={t('production.stockingModal.search')}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          onPressEnter={() => dispatch(stockingAnalysisSlice.setSearchStocking(search))}
          suffix={
            <div
              className={styles.suffixIcon}
              onClick={() => dispatch(stockingAnalysisSlice.setSearchStocking(search))}
            >
              <Icon
                id='search_icon_button'
                name='search'
              />
            </div>
          }
        />
      </div>

      <LrvTable
        className={styles.table}
        theme='light'
        columns={columns}
        dataSource={stockingData}
        loading={isLoadingFetchStockings}
        pagination={false}
        rowClassName={getRowClassName}
        onRow={(record) => {
          return {
            onClick: () => {
              setNewStockingSelected(record._id);
            },
          };
        }}
        scroll={{ y: 240 }}
        size='middle'
      />
    </LrvModal>
  );
};