import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { axiosClient as axios } from '../../../utils/axios_instance';
import { GLOBAL_ANALYSIS_OBSERVATION_URL, COMPANY_ANALYSIS_OBSERVATION_URL } from '../../../config/config.api';

import { CompanyAnalysisObservation, CompanyAnalysisObservationState, GlobalAnalysisObservation } from './interfaces';

const initialState: CompanyAnalysisObservationState = {
  globalAnalysisObservations: [],
  companyAnalysisObservations: [],
  isLoadingGlobalObservation: false,
  isLoadingCompanyObservation: false,
  showCreateObservationModal: false,
  isCreatingObservation: false,
  showEditObservationModal: false,
  isEditingObservation: false,
};

export const stockingParameterSlice = createSlice({
  name: 'companyAnalysisObservation',
  initialState,
  reducers: {
    setGlobalAnalysisObservations: (state: CompanyAnalysisObservationState, action: PayloadAction<GlobalAnalysisObservation[]>) => {
      state.globalAnalysisObservations = action.payload;
    },
    setCompanyAnalysisObservations: (state: CompanyAnalysisObservationState, action: PayloadAction<CompanyAnalysisObservation[]>) => {
      state.companyAnalysisObservations = action.payload;
    },
    
    setIsLoadingGlobalObservation: (state: CompanyAnalysisObservationState, action: PayloadAction<boolean>) => {
      state.isLoadingGlobalObservation = action.payload;
    },
    setIsLoadingCompanyObservation: (state: CompanyAnalysisObservationState, action: PayloadAction<boolean>) => {
      state.isLoadingCompanyObservation = action.payload;
    },

    setShowCreateObservationModal: (state: CompanyAnalysisObservationState, action: PayloadAction<boolean>) => {
      state.showCreateObservationModal = action.payload;
    },
    setIsCreatingObservation: (state: CompanyAnalysisObservationState, action: PayloadAction<boolean>) => {
      state.isCreatingObservation = action.payload;
    },

    setCompanyAnalysisObservation: (state: CompanyAnalysisObservationState, action: PayloadAction<CompanyAnalysisObservation>) => {
      state.companyAnalysisObservation = action.payload;
    },
    setShowEditObservationModal: (state: CompanyAnalysisObservationState, action: PayloadAction<boolean>) => {
      state.showEditObservationModal = action.payload;
    },
    setIsEditingObservation: (state: CompanyAnalysisObservationState, action: PayloadAction<boolean>) => {
      state.isEditingObservation = action.payload;
    },
  },
});

export const {
  setGlobalAnalysisObservations,
  setCompanyAnalysisObservations,
  setIsLoadingGlobalObservation,
  setIsLoadingCompanyObservation,
  setShowCreateObservationModal,
  setIsCreatingObservation,
  setCompanyAnalysisObservation,
  setShowEditObservationModal,
  setIsEditingObservation,
} = stockingParameterSlice.actions;

export const fetchGlobalObservations = () => async (dispatch: Function) => {
  const params = {
    $limit: -1,
  };

  dispatch(setIsLoadingGlobalObservation(true));

  try {
    const response = await axios.get<GlobalAnalysisObservation[]>(GLOBAL_ANALYSIS_OBSERVATION_URL, { params });
    dispatch(setGlobalAnalysisObservations(response.data));
    dispatch(setIsLoadingGlobalObservation(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchCompanyObservations = () => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    '$sort[active]': -1,
  };

  dispatch(setIsLoadingCompanyObservation(true));

  try {
    const response = await axios.get<CompanyAnalysisObservation[]>(COMPANY_ANALYSIS_OBSERVATION_URL, { params });
    dispatch(setCompanyAnalysisObservations(response.data));
    dispatch(setIsLoadingCompanyObservation(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const createCompanyObservation = (props: { body: CompanyAnalysisObservation; onSuccess: () => void; }) => async (dispatch: Function) => {
  const { body, onSuccess } = props;
  dispatch(setIsCreatingObservation(true));

  try {
    await axios.post(COMPANY_ANALYSIS_OBSERVATION_URL, body);
    dispatch(fetchCompanyObservations());
    onSuccess();
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsCreatingObservation(false));
};

export const updateCompanyObservation = (props: { body: CompanyAnalysisObservation; onSuccess: () => void; }) => async (dispatch: Function) => {
  const { body, onSuccess } = props;
  dispatch(setIsEditingObservation(true));
  const url = `${COMPANY_ANALYSIS_OBSERVATION_URL}/${body._id}`;

  try {
    await axios.patch(url, body);
    dispatch(fetchCompanyObservations());
    onSuccess();
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsEditingObservation(false));
};

export default stockingParameterSlice.reducer;
