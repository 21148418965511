import cx from 'classnames';
import { useEffect } from 'react';
import { Layout, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Impersonate from '../Sidebar/Impersonate';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import * as sowingsSlice from '../Sowings/sowingsSlice';
import { filterOptionSelect } from '../../utils/select';
import { getUserSession } from '../../utils/userSession';
import larviaLogoDark from '../../assets/larvia-logo-dark.png';
import larviaLogoLight from '../../assets/larvia-logo-light.png';
import IconButton from '../../common/components/buttons/IconButton';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { SwitchPhase } from '../../common/components/SwitchPhase/SwitchPhase';
import * as stockingAnalysisSlice from '../Sowings/Analysis/stockingAnalysisSlice';
import { getMainRole, isAdminUser, stockingPhaseTypes, stockingsShow, THEME } from '../../config/commons';

import './AppHeader.scss';
import { SwitchTheme } from './SwitchTheme';
import styles from './AppHeader.module.scss';
import * as headerSlice from './headerSlice';
import FullScreenMenu from './FullScreenMenu';
import DownloadTanksQr from './DownloadTanksQr';
import StockingNotification from './StockingNotification';
import { DownloadActiveTanks } from './DownloadActiveTanks';
import { showDropdownCompany, showDropdownPhaseType } from './helpers';

const { Header } = Layout;
const { Option } = Select;

export default function AppHeader () {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const higherRole = getMainRole();
  const { pathname } = useLocation();

  const titleHeader = useSelector((state: Store) => state.header.title);
  const subtitle = useSelector((state: Store) => state.header.subtitle);
  const { company, companies, phaseType } = useSelector((state: Store) => state.header);
  const { completedAnalysisCount } = useSelector((state: Store) => state.adminDashboard);
  const showImpersonateModal = useSelector((state: Store) => state.impersonate.showModal);
  const { analysis: analysisOriginal, share: shareAnalysis } = useSelector((state: Store) => state.detailAnalysis);
  const userSession = getUserSession();

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    if (isAdminUser()) {
      dispatch(headerSlice.fetchCompanies());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!userSession.companyId) {
      return;
    }

    if (pathname.includes('/production/analysis') || (pathname.includes('/production/stockings') && pathname !== '/production/stockings')) {
      return;
    }

    if (company._id === userSession.companyId) {
      return;
    }

    dispatch(headerSlice.fetchCompany(showDropdownCompany() ? company._id || userSession.companyId : userSession.companyId));
  }, [dispatch, pathname, userSession.companyId]);

  useEffect(() => {
    if (pathname !== '/production/insights') {
      return;
    }

    dispatch(stockingAnalysisSlice.setUnitSelected(undefined));
    dispatch(stockingAnalysisSlice.setModuleSelected(undefined));
    dispatch(stockingAnalysisSlice.setContainerSelected(undefined));
    dispatch(stockingAnalysisSlice.setStocking(undefined));

    dispatch(stockingAnalysisSlice.setContainers([]));
    dispatch(stockingAnalysisSlice.setModules([]));
  }, [dispatch, phaseType, company._id]);

  if (!higherRole && !analysisOriginal._id) {
    return null;
  }

  const renderLeftHeader = () => {
    if (subtitle) {
      return (
        <div className={styles.title}>
          {t(titleHeader)}

          <span className={styles.subtitle}>
            {subtitle}
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={styles.title}>
          {t(titleHeader)}
        </div>
        <DownloadActiveTanks />
        <DownloadTanksQr theme={theme} />
      </>
    );
  };

  const renderRightHeader = () => {
    return (
      <>
        {
          (completedAnalysisCount > 0 && pathname.includes('/admin')) &&
          <div className={styles.analysisCount}>
            {completedAnalysisCount} {t('analysis.analysis')}
          </div>
        }

        <div>
          <StockingNotification theme={theme} />
        </div>

        {
          showDropdownPhaseType() &&
          <div id='phase-type-global' className={cx(isDesktop ? styles.extraPadding : '', styles.phaseType)}>
            <SwitchPhase phaseType={phaseType} theme={theme} />
          </div>
        }

        <IconButton
          className={isDesktop ? styles.hamburger : styles.mobileHamburger}
          onClick={() => dispatch(headerSlice.setShowFullScreenMenu(true))}
          icon={<Icon className={isLightTheme ? styles.lightIcon : styles.darkIcon} name='menu' />}
        />
      </>
    );
  };

  const renderCompaniesDropdown = () => {
    if (!showDropdownCompany()) {
      return null;
    }

    return (
      <LrvSelect
        id='dropdown_company'
        className={styles.select}
        theme={theme}
        showSearch
        suffixIcon={<Icon name='arrow-down-s' />}
        placeholder={t('header.company')}
        value={company?._id}
        optionFilterProp='children'
        onChange={(value) => {
          const company = companies.find(item => item._id === value);
          if (!company) {
            return;
          }

          dispatch(stockingAnalysisSlice.setBrowsingFromStocking(false));
          dispatch(headerSlice.setCompany(company));
          dispatch(headerSlice.setPhaseType(userSession.defaultPhase || stockingPhaseTypes.LARVAE));
          dispatch(sowingsSlice.resetStockingFilters({ page: 1, phaseType: userSession.defaultPhase || stockingPhaseTypes.LARVAE, stockingsToShow: stockingsShow.ACTIVE, companyId: company._id }));
        }}
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
      >
        {companies.map((company) =>
          <Option key={company._id} value={company._id}>{company.name}</Option>)
        }
      </LrvSelect>
    );
  };

  const renderHeader = () => {
    const pathname = history.location.pathname;

    if (!higherRole && !pathname) {
      return null;
    }

    if (!higherRole) {
      return (
        <div className={cx(styles.header, isLightTheme ? styles.headerLight : styles.headerDark)}>
          <div className={styles.analysisShared}>
            <img
              className={styles.image}
              src={isLightTheme ? larviaLogoLight : larviaLogoDark}
              height={26}
              alt='Larvia logo'
            />

            <div className={styles.titlePublic}>
              {shareAnalysis.title}
            </div>
          </div>


          <Space className={styles.rightHeader} size='large'>
            <SwitchTheme isAnalysisPublic={true} size='default' />
          </Space>
        </div>
      );
    }

    return (
      <div className={cx(styles.header, isLightTheme ? styles.headerLight : styles.headerDark)}>
        <div className={styles.leftHeader}>
          <div className={styles.rowHeader}>
            {renderLeftHeader()}
          </div>
        </div>

        <div className={styles.centerHeader}>
          {renderCompaniesDropdown()}
        </div>

        <div className={styles.rightHeader}>
          {renderRightHeader()}
        </div>
      </div>
    );
  };

  function renderImpersonateModal () {
    if (showImpersonateModal) {
      return <Impersonate />;
    }
    return null;
  }

  return (
    <Header>
      {renderHeader()}
      <FullScreenMenu />
      {renderImpersonateModal()}
    </Header>
  );
}