import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Campus } from '../Units/interfaces';
import { unitStatuses } from '../../config/commons';
import Icon from '../../common/components/Icon/Icon';
import { Store } from '../../state/store.interfaces';
import IconButton from '../../common/components/buttons/IconButton';
import { updateContainerLocations } from '../Tanks/tanksSlice';

import styles from './DownloadTanksQr.module.scss';

interface Props {
  theme?: 'dark' | 'light';
}

const DownloadTanksQr = (props: Props) => {
  const { theme = 'dark' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { company } = useSelector((state: Store) => state.header);
  const { campuses } = useSelector((state: Store) => state.campus);
  const { addingLocations } = useSelector((state: Store) => state.tanks);

  const activeCampuses = campuses.filter((campus: Campus) => campus.status === unitStatuses.ACTIVE);
  const disabled = activeCampuses.length === 0;

  if (!(pathname.includes('units') && !pathname.includes('weight-units'))) {
    return null;
  }

  return (
    <Space className={styles.qrButton}>
      <IconButton
        className={styles.mapPin}
        disabled={disabled}
        loading={addingLocations}
        icon={<Icon name='map-pin-2' theme={theme} />}
        onClick={() => dispatch(updateContainerLocations({ companyId: company._id }))}
        placement='right'
        tooltipText={disabled ? t('campus.noContainers') : t('campus.addContainerLocations')}
      />
    </Space>
  );
};

export default DownloadTanksQr;
